// eslint-disable-next-line max-len
// todo: move content rendering to consumer, adding a render prop and removing beforeFieldsContent, afterFieldsContent, containerComponent, etc.
// eslint-disable-next-line max-len
// todo: move from initiliazation outside of the component to have an ablitity use form methods in wizard step components

import React, { FC } from 'react';

import { WizardStepProps } from '../types';

import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import { FormProviderWrapper, FormProviderWrapperProps } from '../../components/FormProviderWrapper/FormProviderWrapper';

import { useWizardStepForm } from './useWizardStepForm';
import styles from './WizardStep.pcss';
import { WizardStepAlert } from './WizardStepAlert';

/**
 * Generic wizard step component.
 * It concentrates on fields validation, focusing, and form-related actions.
 */
export const WizardStep: FC<WizardStepProps> = ({
  wizard,
  buttons,
  onSubmit,
  onBack,
  onCancel,
  locators,
  onTerminationStep,
  contentContainer,
  fieldsContainer,
  buttonsContainer,
  beforeFieldsContent,
  afterFieldsContent,
  defaultFieldValues,
  customSubmit,
  isCustomSubmit,
  render,
}) => {
  const {
    form,
    submitHandler,
    fieldsRender,
    buttonsRender,
  } = useWizardStepForm({
    wizard,
    buttons,
    onSubmit,
    onBack,
    onCancel,
    onTerminationStep,
    defaultFieldValues,
    locators,
    customSubmit,
    isCustomSubmit,
  });

  const alertRender = <WizardStepAlert />;

  const ContentContainer = contentContainer || FlexContainer;
  const FieldsContainer = fieldsContainer || FlexContainer;
  const ButtonsContainer = buttonsContainer || FlexContainer;

  const formProviderProps: FormProviderWrapperProps = {
    formProviderProps: form,
    formProps: {
      noValidate: true,
      onSubmit: submitHandler,
    },
  };

  // render the wizard step via render prop if it's provided
  if (render) {
    return (
      <FormProviderWrapper {...formProviderProps}>
        {render({
          fields: fieldsRender,
          buttons: buttonsRender,
          alert: alertRender,
          formState: form.formState,
        })}
      </FormProviderWrapper>
    );
  }

  // else render the wizard step with default layout
  return (
    <FormProviderWrapper {...formProviderProps}>
      <ContentContainer direction="column" className={styles.container}>
        {beforeFieldsContent && beforeFieldsContent({ formState: form.formState })}

        {alertRender}

        <FieldsContainer direction="column" data-stable-name="FieldsContainer">
          {fieldsRender}
        </FieldsContainer>

        {afterFieldsContent && afterFieldsContent({ formState: form.formState })}

        {buttons && (
          <ButtonsContainer direction="column" data-stable-name="ButtonsContainer">
            {buttonsRender}
          </ButtonsContainer>
        )}
      </ContentContainer>
    </FormProviderWrapper>
  );
};
