import dayjs from 'dayjs';

import { DayOfWeek } from '@app/queryTyping';

export function msToSec(ms: number): number {
  return Math.floor((ms % (1000 * 60)) / 1000);
}

export function secToMs(sec: number): number {
  return Math.floor(sec * 1000);
}

export function secToMin(sec: number): number {
  return Math.floor(sec / 60);
}

function getYearMonthDayFromDateString(zuluDateString: string) {
  const [y, m, d] = zuluDateString
    .substring(0, 10)
    .split('-')
    .map((datePart: string) => (parseInt(datePart, 10)));

  return [y, m, d];
}

/**
 * Example:
 * const date = new Date(2022, 7, 30)
 * -> Tue Aug 30 2022 00:00:00 GMT+0300
 * using date.toISOString()
 * -> 2022-08-29T21:00:00.000Z

  * To prevent date such deviations,
  * we should include timezone offset.
  */
export function mitigateTimezone(date: Date): Date {
  let timeZoneOffset = (date.getTimezoneOffset() * 1000 * 60);
  timeZoneOffset = timeZoneOffset >= 0 ? 0 : timeZoneOffset * -1;
  return new Date(date.getTime() + timeZoneOffset);
}

export function isSameDay(date1: Date, date2: Date) {
  const dd1 = date1.getDate();
  const dd2 = date2.getDate();
  const mm1 = date1.getMonth();
  const mm2 = date2.getMonth();
  const yy1 = date1.getFullYear();
  const yy2 = date2.getFullYear();

  return (
    dd1 === dd2
    && mm1 === mm2
    && yy1 === yy2
  );
}

export function isPastDay(date: Date): boolean {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  return date.getTime() < tomorrow.getTime();
}

/**
 * Receive:
 * 2022-08-29T21:00:00.000Z
 * Convert to:
 * 08/29/2022
 * Format MM/DD/yyyy
 * Return empty string for not date string
 */
export function getDateString(zuluTimeString: string): string {
  if (!(new Date(zuluTimeString)).getDate()) return '';

  const [y, m, d] = getYearMonthDayFromDateString(zuluTimeString);

  return `${m < 10 ? `0${m}` : m}/${d < 10 ? `0${d}` : d}/${y}`;
}

/**
 * Receive:
 * 2022-08-29T21:00:00.000Z
 * Convert to:
 * August 29, 2022
 * Format MMMM DD, YYYY
 * Return empty string for not date string
 */
export function getFullMonthDateString(zuluTimeString: string): string {
  if (!(new Date(zuluTimeString)).getDate()) return '';

  const [y, m, d] = getYearMonthDayFromDateString(zuluTimeString);

  const date = new Date(y, m - 1, d);

  // eslint-disable-next-line i18next/no-literal-string
  return `${date.toLocaleString('en-US', { month: 'long' })} ${d < 10 ? `0${d}` : d}, ${y}`;
}

/**
 * Determine is date zulu time string in future
 * Return false for not date string
 */
export function isFutureDateString(zuluTimeString: string): boolean {
  if (!zuluTimeString) return false;

  if (!(new Date(zuluTimeString)).getDate()) return false;

  const [y, m, d] = getYearMonthDayFromDateString(zuluTimeString);

  const localNow = new Date(Date.now());

  if (y > localNow.getFullYear()) return true;
  if (y === localNow.getFullYear() && (m - 1) > localNow.getMonth()) return true;
  if (y === localNow.getFullYear() && (m - 1) === localNow.getMonth() && d > localNow.getDate()) return true;

  return false;
}

/**
 * @param holidays string[] with dates, which are represented as YYYY-MM-DD string
 * @param date Date object, which we need to check
 * @returns boolean
 */
export const isHoliday = (holidays: string[], date: Date): boolean => (
  Boolean(holidays
    .find((zuluDateString) => {
      const [y, m, d] = getYearMonthDayFromDateString(zuluDateString);

      return isSameDay(date, new Date(y, m - 1, d));
    }))
);

/**
 * @param excludedDays any[], but in fact DayOfWeek[], with day names (e.g. SUNDAY, SATURDAY)
 * @param date Date object, which we need to check
 * @returns boolean
 */
export const isNonWorkDay = (excludedDays: any[], date: Date): boolean => {
  if (excludedDays) {
    const dateDayName: DayOfWeek = (Object.keys(DayOfWeek) as DayOfWeek[])[date.getDay()];
    return excludedDays.indexOf(dateDayName) !== -1;
  }
  return false;
};

/**
 * Receive:    PT10H30M
 * Convert to: Date object
 * Return null if time span is not valid
 */
export function timeSpanToDate(value: string): Date | null {
  const timeSpan = dayjs.duration(value);
  const hours = timeSpan.hours();
  const minutes = timeSpan.minutes();
  const seconds = timeSpan.seconds();

  /**
   * By default dayjs returns NaN in case when it shold be a zero.
   *
   * Due to issue with converting durations in dayjs
   * we should check that time unit is not a number.
   *
   * See https://github.com/iamkun/dayjs/pull/1513 for details.
   */
  const date = new Date();
  date.setHours(Number.isNaN(hours) ? 0 : hours);
  date.setMinutes(Number.isNaN(minutes) ? 0 : minutes);
  date.setSeconds(Number.isNaN(seconds) ? 0 : seconds);

  /**
   * Checking the result date because hours, minutes,
   * seconds can be undefined.
   */
  return dayjs(date).isValid() ? date : null;
}

/**
 * Receive:    Date object
 * Convert to: PT10H30M
 * Return null if date is not valid
 */
export function dateToTimeSpan(value: Date): string | null {
  const date = dayjs(value);

  return date.isValid() ? dayjs.duration({
    hours: date.hour(),
    minutes: date.minute(),
    seconds: date.second(),
  }).toISOString() : null;
}

/**
 * @param month number zero baased month number
 * @returns number
 */
export const getDaysInMonth = (month: number): number => {
  const date = new Date();

  date.setMonth(month + 1);
  date.setDate(0);

  return date.getDate();
};
