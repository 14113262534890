import React from 'react';

import { ImageWidget, ThemeAwareImageWidget } from '@app/queryTyping';

import { createIconProvider } from '@app/common/utils/iconsProvider';

import { ThemedIcon } from '@app/core/components/ThemedIcon/ThemedIcon';

import { PureWidget, WidgetRenderer, WidgetType } from './types';
import styles from './widgets.pcss';

/**
 * Image provider for image widgets.
 */
const widgetImageProvider = createIconProvider();

/**
 * Image widget typeguard.
 * @param widget orchard widget.
 * @returns true if widget is image widget.
 */
export function isImageWidget(widget: WidgetType): widget is PureWidget<ImageWidget> {
  return widget.__typename === 'ImageWidget' && widget.path !== undefined;
}

/**
   * ThemeImageWidget typeguard
   * @param widget widget
   * @returns true is widget is ThemeImageWidget
   */
export function isThemeImageWidget(widget: WidgetType): widget is
    PureWidget<ThemeAwareImageWidget> {
  return widget.__typename === 'ThemeAwareImageWidget'
      && (widget.lightThemePath !== undefined || widget.darkThemePath !== undefined);
}

/**
 * Inserts html in container and create portals for all widgets found in that html.
 * @param container container element.
 * @param html html to insert.
 * @param addPortal callback to add created portal.
 */
export const ImageWidgetRenderer: WidgetRenderer<ImageWidget> = (
  { widget },
) => widgetImageProvider(widget?.path!, styles.image) || null;

export const ThemedImageRenderer: WidgetRenderer<ThemeAwareImageWidget> = (
  { widget },
) => (
  <ThemedIcon
    className={styles.image}
    darkThemeIconPath={widget?.darkThemePath!}
    lightThemeIconPath={widget?.lightThemePath!}
    iconProvider={widgetImageProvider}
  />
);
