import React from 'react';

import { ConnectedWizardStep, WizardStep } from '@app/common/configurable-wizards';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/wizard-step/WizardStepWithHeader';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginWizardButtons } from '../../LoginWizardButtons';
import { LabelAsPlainTextLocator } from './fields/InfoField.LabelFieldInfo';

import styles from './index.pcss';

export const ValidateOneTimePin: React.FC<ConnectedWizardStep> = ({
  locators,
  ...restParams
}) => {
  const { t } = useUserLoginTranslation();
  const stepLocators = [LabelAsPlainTextLocator, ...(locators || [])];

  return (
    <WizardStepWithHeader
      title={t('validate-one-time-pin.title|Validate One Time Pin step title', 'Enter PIN code')}
      contentClassName={styles.content}
    >
      <WizardStep
        locators={stepLocators}
        {...restParams}
        buttons={({ back }) => (
          <LoginWizardButtons
            showBackButton={false}
            cancelTitle={t('validate-one-time-pin.wizard.buttons.cancel.title|Validate One Time Pin step cancel button title', 'Return to login')}
            back={back}
            direction="column"
          />
        )}
      />
    </WizardStepWithHeader>
  );
};
