import React, { useState } from 'react';

import { ActiveSlidesIndicator } from './components/ActiveSlidesIndicator/ActiveSlidesIndicator';
import { Slides } from './components/Slides/Slides';
import { useScrollHandler } from './hooks/useScrollHandler';

import styles from './Carousel.pcss';
import { CarouselSlide } from './types';

/**
 * {@link Carousel} component properties.
 */
export type CarouselProps = Readonly<{
  /**
   * Carousel slides to render.
   */
  slides: CarouselSlide[];
}>;

/**
 * A carousel component that renders slides and an active slide indicator.
 */
export const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesOverlapping, setSlidesOverlapping] = useState(false);

  const { containerRef, scrollToSlide } = useScrollHandler({
    slides,
    activeIndex,
    setActiveIndex,
    setSlidesOverlapping,
  });

  return (
    <div className={styles.container}>
      <Slides
        slides={slides}
        ref={containerRef}
      />
      <ActiveSlidesIndicator
        slides={slides}
        slidesOverlapping={slidesOverlapping}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        scrollToSlide={scrollToSlide}
      />
    </div>
  );
};
