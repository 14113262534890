import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  MutableRefObject,
  useMemo,
} from 'react';

import {
  moveNextChildGenerator,
  moveNextGenerator,
  movePreviousGenerator,
  findFocusableElement,
  moveFocus,
} from '@ast/magma/utils/focus';

import { t } from '@app/core/i18n';

import Navigation from './nav/nav';
import { TabProps } from './tab/tab';

import { Context, ContextProps } from './context';
import { TabPropsExt } from './tab';

/**
 * Tabs properties.
 */
export interface TabsProps {
  /**
   * Callback fired when the selected value is changed
   */
  readonly onChange?: (idx?: number) => void

  /**
   * Index of current selected tab
   */
  readonly current?: number

  /**
   * Amount of fixed width of each tab
   */
  readonly tabWidth?: string

  /**
   * Contained tabs.
   */
  readonly children?: ReactElement<TabProps> | ReactElement<TabProps>[]
}

export const Tabs = React.forwardRef((props: TabsProps, sectionRef: React.Ref<HTMLElement>) => {
  const {
    onChange,
    current: propCurrent,
    tabWidth,
    children,
  } = props;

  const tabs: MutableRefObject<TabPropsExt[]> = useRef([]);
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null);
  const tabListRef = useRef<HTMLDivElement>(null);
  const isFocusedInsideContent = useRef<boolean>(false);
  const [, setInitialRendered] = useState<boolean>(false);
  const [current, setCurrent] = useState(propCurrent);

  const updateCurrent = (index?: number): void => {
    setCurrent(index);

    if (onChange) {
      onChange.call(null, index);
    }
  };

  const getContextValue = (): ContextProps => ({
    tabs,
    current,
    tabWidth,
    updateCurrent,
    isContentInFocus: () => isFocusedInsideContent.current,
  });

  useEffect(() => {
    setInitialRendered(true);
  }, []);

  useEffect(() => {
  }, [propCurrent]);

  useMemo(() => {
    if (typeof propCurrent !== 'number' && contentRef.current !== null) {
      isFocusedInsideContent.current = contentRef.current.contains(document.activeElement);
    }

    setCurrent(propCurrent);
  }, [propCurrent]);

  const handleFocusContent = () => {
    moveFocus(moveNextChildGenerator(contentRef.current, false));
  };

  const handleTabKeyDown = (event:React.KeyboardEvent<HTMLElement>) => {
    if (event.isDefaultPrevented() || current == null) return;

    if (event.key === 'ArrowUp') {
      if (moveFocus(moveNextChildGenerator(tabListRef.current, false))) event.preventDefault();
    } else if (event.key === 'ArrowLeft') {
      if (current > 0) {
        const button = findFocusableElement(moveNextChildGenerator(tabListRef.current, false),
          true);
        if (moveFocus(movePreviousGenerator(button, false), false)) event.preventDefault();
      }
    } else if (event.key === 'ArrowRight') {
      if (current < tabs.current.length - 1) {
        const button = findFocusableElement(moveNextChildGenerator(tabListRef.current, false),
          true);
        if (moveFocus(moveNextGenerator(button, false), false)) event.preventDefault();
      }
    }
  };

  const modifiedChildren: React.ReactElement[] = useMemo(() => {
    if (!children) {
      return [];
    }

    let childrenCopy = children;

    if (!Array.isArray(childrenCopy)) {
      childrenCopy = [childrenCopy];
    }

    return [...(childrenCopy as Array<ReactElement>)]
      .map((child: React.ReactElement, index: number) => {
        const res = React.cloneElement(child, {
          key: child.props.label,
          index,
          onKeyDown: handleTabKeyDown,
        });
        return res;
      });
  }, [children]);

  return (
    <Context.Provider value={getContextValue()}>
      <nav
        ref={sectionRef}
        aria-label={t(
          'page-layout.main-menu|Accessible text for main navigation menu in Header',
          'Main menu',
        )}
      >
        <Navigation ref={tabListRef} onFocusContent={handleFocusContent} />
        <div ref={contentRef}>
          {modifiedChildren}
        </div>
      </nav>
    </Context.Provider>
  );
});

Tabs.defaultProps = {
  current: undefined,
};
