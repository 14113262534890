import {
  useEffect,
  useLayoutEffect,
  useRef,
  RefObject,
} from 'react';

export const useResizeObserver = (callback: (entry: ResizeObserverEntry) => void, ref: RefObject<HTMLElement>) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      callbackRef.current(entries[0]);
    });

    resizeObserver.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);
};
