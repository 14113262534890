import React from 'react';

import { ConnectedWizardStep, WizardStep } from '@app/common/configurable-wizards';
import { AgreementFieldLocator } from '@app/common/configurable-wizards/fields/AgreementField';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/wizard-step/WizardStepWithHeader';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginWizardButtons } from '../../LoginWizardButtons';

export const UserAgreement: React.FC<ConnectedWizardStep> = ({
  locators,
  ...restParams
}) => {
  const { t } = useUserLoginTranslation();
  const stepLocators = [AgreementFieldLocator, ...(locators || [])];
  const { name, allowBackRedirect } = useWizardStepStateContext();

  return (
    <WizardStepWithHeader
      title={name || ''}
    >
      <WizardStep
        locators={stepLocators}
        {...restParams}
        buttons={({ back }) => (
          <LoginWizardButtons
            showBackButton={allowBackRedirect}
            showCancelButton
            continueTitle={t(
              'user-agreement.wizard.buttons.continue.title|User agreement step continue button title',
              'Continue',
            )}
            back={back}
            direction="row"
          />
        )}
      />
    </WizardStepWithHeader>
  );
};
