import { useEffect } from 'react';

import { ApolloError, MutationHookOptions, MutationTuple } from '@apollo/client';

import { CurrentWizardStep } from '@app/queryTyping';

import { WizardStepStateDispatcher } from '@app/common/configurable-wizards/state/types';
import { getServerErrors } from '@app/common/utils/serverValidation';

import { BASE_MUTATION_OPTIONS } from '../constants';
import { isNextStep, isHasErrors } from '../utils';

import {
  setWizardStepFieldsErrors, setWizardStepGenericErrors, setWizardStepLoading, setWizardStepUnhandledErrors,
} from '../state/stepState';

type MutationHook = (options: MutationHookOptions<any, any>) => MutationTuple<any, any>;

/**
 * Generic hook to mutate a wizard step
 * @param mutation - An initial Apollo useMutation hook
 * @param options  - Apollo useMutation hook options
 * @param dispatch - wizard step state disptacher
 * @returns Apollo useMutation return type
 */
export function usePostWizardStepMutation<
  T extends MutationHook,
  P extends Parameters<T>[0],
  >(
  mutation: T,
  options: P,
  dispatch: WizardStepStateDispatcher,
): ReturnType<T> {
  const mutationTuple = mutation({
    ...BASE_MUTATION_OPTIONS,
    ...options,
    //
    onCompleted: (result) => {
      // call external onCompleted callback
      if (options?.onCompleted) {
        options.onCompleted(result);
      }

      // todo: create more stable solution to get CurrentWizardStep
      const currentWizardStep = Object.values(result)[0] as unknown as CurrentWizardStep;
      // eslint-disable-next-line i18next/no-literal-string
      if (!currentWizardStep) throw new Error('Can\'t detect wizard step');

      if (isNextStep(currentWizardStep)) {
        // show validation errors
        if (isHasErrors(currentWizardStep)) {
          const { genericErrors, fieldErrors } = getServerErrors(currentWizardStep.errors);

          if (genericErrors) {
            dispatch(setWizardStepGenericErrors(genericErrors));
          }

          if (fieldErrors) {
            dispatch(setWizardStepFieldsErrors(fieldErrors));
          }
        }
      }
    },
    onError: (error: ApolloError) => {
      dispatch(setWizardStepUnhandledErrors([error]));

      // call external onError callback
      if (options?.onError) {
        options.onError(error);
      }
    },
  });

  const [, mutationResult] = mutationTuple;

  useEffect(() => {
    dispatch(setWizardStepLoading(mutationResult.loading));
  }, [mutationResult.loading]);

  return mutationTuple as ReturnType<T>;
}
