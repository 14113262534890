import { ZoneName } from '../constants';

/**
 * Check if the zone should render widgets in a carousel.
 *
 * The carousel is used to display multiple widgets in a single zone, allowing the user to scroll through them.
 *
 * Useful for banner zones like {@link ZoneName.HeaderBanner} and {@link ZoneName.FooterBanner}.
 */
export function hasWidgetCarousel(zoneName: ZoneName) {
  return (
    zoneName === ZoneName.HeaderBanner
    || zoneName === ZoneName.FooterBanner
  );
}
