import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { FormFieldAssistiveLine } from '@ast/magma/components/formfield/formfieldassistive/formfieldassistive';

import { ReCaptchaVersionCodes } from '@app/queryTyping';

import { useGetRecaptchaSettings } from '@app/common/components/ReCaptcha/useGetRecaptchaSettings';
import { useRecaptchaV3 } from '@app/common/components/ReCaptcha/useReCaptchaV3';

import { RECAPTCHA_TOKEN_KEY, RECAPTCHA_VERSION_KEY } from '@app/core/components/ReCaptcha/reCaptchaApolloLink';
import { setSessionValue } from '@app/core/storage/browser/sessionStorage';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import styles from './useReCaptcha.pcss';
import { useReCaptchaValidationException } from './useReCaptchaValidationException';

export const RECAPTCHA_EXECUTE_ACTION = 'login_submit';

export const useReCaptcha = (props: {
  onRecaptchaV2ChallengePassed: () => void,
  colorTheme: 'light' | 'dark',
}) => {
  const { t } = useUserLoginTranslation();
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const recaptchaSettings = useGetRecaptchaSettings();
  const recaptchaValidationException = useReCaptchaValidationException();
  const showRecaptchaChallenge = !!recaptchaValidationException;
  const [recaptchaChallengePassed, setRecaptchaChallengePassed] = useState<boolean>();

  useEffect(() => {
    if (recaptchaValidationException?.version === ReCaptchaVersionCodes.VERSION_2) {
      // if recaptcha server validation fails, reset the recaptcha
      recaptchaRef.current?.reset();
      // then set the challenge passed to false
      setRecaptchaChallengePassed(false);
    }
  }, [recaptchaValidationException]);

  const { executeRecaptcha: executeV3Recaptcha, waitForRecaptcha } = useRecaptchaV3({
    siteKey: recaptchaSettings.siteKeyV3,
    bypass: !recaptchaSettings.isEnabled || showRecaptchaChallenge,
  });

  const onRecapthcaVerified = useCallback((token: string | null) => {
    setSessionValue(RECAPTCHA_TOKEN_KEY, token || '');
    setSessionValue(RECAPTCHA_VERSION_KEY, ReCaptchaVersionCodes.VERSION_2);
    setRecaptchaChallengePassed(true);
    props.onRecaptchaV2ChallengePassed();
  }, [props.onRecaptchaV2ChallengePassed]);

  const onRecapthcaExpired = useCallback(() => {
    setSessionValue(RECAPTCHA_TOKEN_KEY, '');
    setRecaptchaChallengePassed(false);
  }, []);

  const onSubmit = useCallback(async (submitForm: () => void) => {
    if (recaptchaSettings?.isEnabled) {
      // v3 recaptcha
      if (!showRecaptchaChallenge) {
        await waitForRecaptcha();

        const tokenV3 = await executeV3Recaptcha(RECAPTCHA_EXECUTE_ACTION);
        setSessionValue(RECAPTCHA_TOKEN_KEY, tokenV3 || '');
        setSessionValue(RECAPTCHA_VERSION_KEY, ReCaptchaVersionCodes.VERSION_3);
        submitForm();
      } else if (recaptchaChallengePassed) {
        submitForm();
      }
    } else { // v2 recaptcha
      submitForm();
    }
  }, [
    recaptchaSettings?.isEnabled,
    showRecaptchaChallenge,
    recaptchaChallengePassed,
    executeV3Recaptcha,
  ]);

  const renderVisibleRecaptcha = () => (
    recaptchaSettings?.isEnabled
      ? (
        <div className={styles.recaptchaContainer}>
          {showRecaptchaChallenge && (
            <ReCAPTCHA
              sitekey={recaptchaSettings.siteKeyV2!}
              ref={recaptchaRef}
              onChange={onRecapthcaVerified}
              onExpired={onRecapthcaExpired}
              theme={props.colorTheme}
              data-stable-name="ReCaptcha"
            />
          )}
          {showRecaptchaChallenge && !recaptchaChallengePassed && (
            <FormFieldAssistiveLine
              error={t(
                'login.steps.username-password.recapctha.validation.message|Sign in button text',
                'Please verify that you are not a robot',
              )}
              className={styles.recaptchaError}
              data-stable-name="ReCaptchaError"
            />
          )}
        </div>
      )
      : null
  );

  return {
    onSubmit,
    visibleRecaptchaRender: renderVisibleRecaptcha(),
  } as {
    onSubmit: (submitForm: () => void) => void,
    visibleRecaptchaRender: React.ReactNode,
  };
};
