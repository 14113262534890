import { useEffect, useMemo, useState } from 'react';

import { ZoneName } from '../constants';
import { ReadyZoneWidget, ZoneWidget } from '../types';

import { isConditionalWidget } from '../utils/isConditionalWidget';
import { resolveConditionalZoneWidget } from '../utils/resolveConditionalZoneWidget';
import { resolvePersistentZoneWidget } from '../utils/resolvePersistentZoneWidget';

import { useZoneWidgetData } from './useZoneWidgetData';

/**
 * Zone widgets state.
 */
export type ZoneWidgetsState = Readonly<{
  /**
   * Whether the zone widgets are loading.
   */
  loading: boolean;

  /**
   * Zone widgets that are ready to be displayed.
   */
  readyWidgets: ReadyZoneWidget[];

  /**
   * All zone widgets including the `pending` and conditional ones that are possibly excluded from the rendering.
   */
  zoneWidgets: ZoneWidget[];

  /**
   * Whether all zone widgets are ready to be displayed.
   */
  isCompleted: boolean;
}>;

/**
 * Resolves widgets for a specific zone, including global and page widgets.
 *
 * Additionally, it renders conditional widgets when they are ready or skips them otherwise.
 */
export const useZoneWidgets = (zoneName: ZoneName): ZoneWidgetsState => {
  const [loading, setLoading] = useState(true);

  const { data, loading: dataLoading } = useZoneWidgetData(zoneName);

  const [zoneWidgets, setZoneWidgets] = useState<ZoneWidget[]>([]);

  useEffect(() => {
    if (dataLoading) {
      return;
    }

    const widgets = data.map((type, index) => {
      // eslint-disable-next-line no-underscore-dangle
      const id = `${type.__typename}${index}`;

      const isConditional = isConditionalWidget(type);

      // Non-conditional widgets are ready to render immediately.
      if (!isConditional) {
        return resolvePersistentZoneWidget(id, type, zoneName);
      }

      // Conditional widgets are updating their state when ready.
      const [pendingWidget, resolveWidget] = resolveConditionalZoneWidget(id, type, zoneName);
      resolveWidget()
        .then((readyWidget) => {
          setZoneWidgets((prevState) => ([...prevState.slice(0, index), readyWidget, ...prevState.slice(index + 1)]));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console, i18next/no-literal-string
          console.error('Error while resolving conditional widget:', error);
        });

      return pendingWidget;
    });

    setZoneWidgets(widgets);

    setLoading(false);
  }, [data, dataLoading, zoneName]);

  const readyWidgets = useMemo(
    // TypeScript 4.x is not able to properly infer the final type, so the type cast is required.
    () => zoneWidgets.filter((widget) => widget.state === 'ready') as ReadyZoneWidget[],
    [zoneWidgets],
  );

  const isCompleted = useMemo(
    () => !loading && zoneWidgets.every((widget) => widget.state === 'ready'),
    [zoneWidgets, loading],
  );

  return {
    loading,
    readyWidgets,
    zoneWidgets,
    isCompleted,
  };
};
