import { useEffect, useRef } from 'react';

import { useDebouncedFunction } from '@app/common/utils/hooks/useDebouncedFunction';
import { useResizeObserver } from '@app/common/utils/hooks/useResizeObserver/useResizeObserver';

import { CarouselSlide } from '../types';

import { hasHorizontalOverlap } from '../utils/hasHorizontalOverlap';

export interface UseScrollHandlerProps {
  slides: CarouselSlide[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  setSlidesOverlapping: (state: boolean) => void;
}

export const useScrollHandler = ({
  slides,
  activeIndex,
  setActiveIndex,
  setSlidesOverlapping,
}: UseScrollHandlerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToSlide = (index: number) => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const slideElement = containerRef.current.children[index] as HTMLElement;

      if (slideElement) {
        const isLastSlide = index === slides.length - 1;

        let scrollLeft = slideElement.offsetLeft;

        if (isLastSlide) {
          scrollLeft = containerRef.current.scrollWidth - containerWidth;
        }

        containerRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

      let closestSlideIndex = activeIndex;

      // Detect if scrolled to end
      if (scrollLeft + clientWidth >= scrollWidth - 1) {
        closestSlideIndex = slides.length - 1;
      } else if (scrollLeft <= 0) {
        // Detect if scrolled to start
        closestSlideIndex = 0;
      } else {
        // Normal detection of closest slide otherwise
        slides.forEach((_, index) => {
          const slideElement = containerRef.current!.children[index] as HTMLElement;
          const { offsetLeft } = slideElement;
          const slideWidth = slideElement.offsetWidth;

          if (scrollLeft >= offsetLeft + slideWidth / 4) {
            closestSlideIndex = index + 1;
          }
        });
      }

      if (closestSlideIndex !== activeIndex) {
        setActiveIndex(closestSlideIndex);
      }
    }
  };

  const debouncedHandleScroll = useDebouncedFunction(handleScroll, 100);

  const calculateOverlap = () => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    setSlidesOverlapping(hasHorizontalOverlap(container));
  };

  useResizeObserver(() => calculateOverlap(), containerRef);

  useEffect(() => {
    if (containerRef.current) {
      const rafId = requestAnimationFrame(() => calculateOverlap());

      containerRef.current.addEventListener('scroll', debouncedHandleScroll);

      return () => {
        cancelAnimationFrame(rafId);
        if (containerRef.current) {
          containerRef.current.removeEventListener('scroll', debouncedHandleScroll);
        }
      };
    }
  }, [slides, debouncedHandleScroll, setSlidesOverlapping]);

  useEffect(() => {
    if (containerRef.current && containerRef.current.children.length > 0) {
      scrollToSlide(activeIndex);
    }
  }, [activeIndex, slides]);

  return {
    containerRef,
    scrollToSlide,
  };
};
