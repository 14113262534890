import { DEFAULT_SLIDE_WIDTH } from '../constants';
import { CarouselSlide } from '../types';

/**
 * Defines the slide columns for the grid layout.
 */
export function collectSlideTemplateColumns(slides: CarouselSlide[]): string {
  const columns = slides.map((slide) => slide.width ?? DEFAULT_SLIDE_WIDTH);

  return columns.join(' ');
}
