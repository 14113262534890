import React, { useMemo } from 'react';

import { Carousel } from '@app/common/components/Carousel/Carousel';
import { CarouselSlide } from '@app/common/components/Carousel/types';

import { ReadyZoneWidget } from '../../types';

import { makeCarouselSlides } from './makeCarouselSlides';

/**
 * {@link WidgetCarousel} properties.
 */
export type WidgetCarouselProps = Readonly<{
  /**
   * Widgets to render in the carousel.
   */
  widgets: ReadyZoneWidget[];
}>;

/**
 * A carousel for widgets.
 */
export const WidgetCarousel: React.FC<WidgetCarouselProps> = ({ widgets }) => {
  const slides: CarouselSlide[] = useMemo(() => makeCarouselSlides(widgets), [widgets]);

  return (
    <Carousel slides={slides} />
  );
};
