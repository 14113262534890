import { PlatformKind } from '@app/queryTyping';

/**
 * Check if the widget should be hidden for the current platform.
 */
export function isHiddenWidget(widgetPlatform: PlatformKind, currentPlatform: PlatformKind): boolean {
  // There is no platform where the widget should be hidden.
  if (widgetPlatform === PlatformKind.BOTH) {
    return false;
  }

  return widgetPlatform !== currentPlatform;
}
