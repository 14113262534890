import { useEffect } from 'react';

import {
  useForceUserLogoutNotificationSubscription,
} from '../../../widgets/queries/queryTyping/forceUserLogoutNotificationSubscription';
import { isEmbeddedMode } from '../../embeddedMode/embeddedMode';
import { useIsLoggedIn } from '../../login/isLoggedIn';

import { forceLogout } from './forceLogout';

const getLogoutExceptionInstance = () => {
  let needLogoutException = false;

  const setLogoutException = (exception: boolean) => {
    needLogoutException = exception;
  };

  const isNeedLogoutException = () => needLogoutException;

  return {
    isNeedLogoutException,
    setLogoutException,
  };
};

export const logoutException = getLogoutExceptionInstance();

export const useForceLogoutSubscription = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data } = useForceUserLogoutNotificationSubscription({
    skip: !isLoggedIn,
  });

  useEffect(() => {
    if (data?.forceUserLogoutNotification) {
      if (logoutException.isNeedLogoutException()) {
        logoutException.setLogoutException(false);
      } else if (!isEmbeddedMode()) {
        forceLogout(true);
      }
    }
  }, [data]);
};
