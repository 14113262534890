import { logout, logoutNoRedirect } from '@app/core/authentication/logout/logout';

import { needAnonimousLogout } from '../anonimousLogout/anonimousLogout';
import { needForceLogout } from '../forceLogout/needForceLogout';

// Types of logout
export enum LogoutType {
  REDIRECT,
  NO_REDIRECT,
  NO_LOGOUT,
}

// Logout methods by type
export const LogoutTypeMap = {
  [LogoutType.REDIRECT]: logout,
  [LogoutType.NO_REDIRECT]: logoutNoRedirect,
  [LogoutType.NO_LOGOUT]: null,
};

// Get logout type from strategy
// if from DS returns the need of force logout,
// that means we need to logout the user despite everything
export const getLogoutType = (isBackendForceLogout?: boolean):LogoutType => {
  if (needAnonimousLogout()) {
    return LogoutType.NO_REDIRECT;
  }
  if (needForceLogout() || isBackendForceLogout) {
    return LogoutType.REDIRECT;
  }

  return LogoutType.NO_LOGOUT;
};

// If logout strategy conditions met call mapped method
export const callLogoutType = async (isBackendForceLogout?: boolean): Promise<void> => {
  const logoutType = getLogoutType(isBackendForceLogout);
  const typeCall = LogoutTypeMap[logoutType];
  if (typeCall) {
    await typeCall();
  }
};
