import { Result } from '../types';

import { tryParseURL } from './tryParseURL';

/**
 * A parsed absolute URL.
 */
export type ParsedAbsoluteURL = { type: 'absolute'; url: URL };

/**
 * A parsed relative URL.
 */
export type ParsedRelativeURL = { type: 'relative'; url: URL };

/**
 * The result of parsing a URL.
 */
export type URLParseResult = Result<ParsedAbsoluteURL | ParsedRelativeURL>;

/**
 * Tries to parse a string into an URL object.
 *
 * Returns a parse result containing either a parsed absolute URL or a parsed relative URL or an error.
 */
export function safeParseURL(url: string, baseFallback: string): URLParseResult {
  // Check if the URL is absolute and valid.
  const absolute = tryParseURL(url);
  if (absolute.type === 'success') {
    return {
      type: 'success',
      value: {
        type: 'absolute',
        url: absolute.value,
      },
    };
  }

  // If the URL can not be parsed as an absolute URL, try to parse it as a relative URL using the base URL.
  const relative = tryParseURL(url, baseFallback);
  if (relative.type === 'success') {
    return {
      type: 'success',
      value: {
        type: 'relative',
        url: relative.value,
      },
    };
  }

  // If the URL can not be parsed as a relative URL, return the error.
  return {
    type: 'error',
    error: relative.error,
  };
}
