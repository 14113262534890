import React, { FC } from 'react';

import classNames from 'classnames';

import { CarouselSlide } from '../../types';

import styles from './ActiveSlidesIndicator.pcss';

interface ActiveSlidesIndicatorProps {
  slides: CarouselSlide[];
  slidesOverlapping: boolean;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  scrollToSlide: (index: number) => void;
}

export const ActiveSlidesIndicator: FC<ActiveSlidesIndicatorProps> = ({
  slides,
  slidesOverlapping,
  activeIndex,
  setActiveIndex,
  scrollToSlide,
}) => {
  if (slides.length <= 1 || !slidesOverlapping) {
    return null;
  }

  return (
    <div className={styles.dots}>
      {slides.map((slide, index) => (
        <button
          key={slide.element?.key}
          type="button"
          onClick={() => {
            setActiveIndex(index);
            scrollToSlide(index);
          }}
          className={classNames(styles.dot, {
            [styles.activeDot]: index === activeIndex,
          })}
          aria-label={`Go to slide ${index + 1}`}
        />
      ))}
    </div>
  );
};
