import { setSessionValue, getSessionValue, removeSessionValue } from '@app/core/storage/browser/sessionStorage';

export const STORAGE_KEY = 'user.id.hash';

export const setUserIdHash = (uidHash: string): void => {
  setSessionValue(STORAGE_KEY, uidHash);
};

export const getUserIdHash = () => getSessionValue(STORAGE_KEY);

export const resetUserIdHash = (): void => {
  removeSessionValue(STORAGE_KEY);
};

export default {
  setUserIdHash,
  getUserIdHash,
  resetUserIdHash,
  STORAGE_KEY,
};
