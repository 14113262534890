// eslint-disable-next-line max-len
// todo: move content rendering to consumer, adding a render prop and removing beforeFieldsContent, afterFieldsContent, containerComponent, etc.
// eslint-disable-next-line max-len
// todo: move from initiliazation outside of the component to have an ablitity use form methods in wizard step components

import React, {
  FormEventHandler,
  useEffect,
  useMemo,
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import { moveFocus, moveNextGenerator } from '@ast/magma/utils/focus';

import {
  Field,
  FieldDataObject,
  WizardStepProps,
} from '../types';
import {
  serializeResult,
} from '../utils';
import { useWizardStepStateContext } from '../WizardStateStateContext';

import { FieldServerErrors } from '../../types/validation';
import { isEmptyArray, isEmptyObject, isFunction } from '../../utils';
import { getFirstInvalidFieldName } from '../../utils/form/getFirstFieldError';
import {
  createLocator,
} from '../fields/multilocator';
import { useDefaultValues } from '../hooks/useDefaultValues';

/**
 * TODO: implement a centralized way to disable user interaction with fields during submitting the form
 */
export const blurActiveElement = () => {
  if (document.activeElement) {
    const activeElement = document.activeElement as HTMLElement;
    activeElement.blur();
  }
};

/**
 * Generic wizard step component.
 * It concentrates on fields validation, focusing, and form-related actions.
 */
export const useWizardStepForm = ({
  wizard,
  buttons,
  defaultFieldValues,
  onSubmit,
  onBack,
  onCancel,
  onTerminationStep,
  customSubmit,
  isCustomSubmit,
  locators,
}: Pick<WizardStepProps, 'wizard' | 'defaultFieldValues' | 'onSubmit' | 'locators' | 'onTerminationStep' | 'buttons' | 'onBack' | 'onCancel' | 'customSubmit' | 'isCustomSubmit'>): {
  form: UseFormReturn;
  submitHandler: FormEventHandler;
  fieldsRender: JSX.Element[];
  buttonsRender?: React.ReactElement | null;
} => {
  const {
    id,
    fields,
    fieldErrors,
    fieldsUiProps,
  } = useWizardStepStateContext();

  const stepId = id;
  const defaultValues = useDefaultValues({
    wizard,
    stepId,
    fields,
    defaultFieldValues,
  });

  const form = useForm({
    defaultValues,
    criteriaMode: 'firstError',
  });

  // update defaults values when fields list was changed
  useEffect(() => {
    form.reset(defaultValues);
  }, [form.reset, fields, defaultValues]);

  // Present to user the first field with error (validation or server-side)
  // by scrolling and setting focus into the field with fieldID.
  const focusField = (fieldID: string) => {
    const invalidFields = document.querySelectorAll(`[name="${fieldID}"]`);
    if (invalidFields.length) {
      const lastInvalidField = invalidFields[invalidFields.length - 1];

      // scroll to show the error
      if (isFunction(lastInvalidField.scrollIntoView)) {
        lastInvalidField.scrollIntoView({ block: 'center' });
      }

      // focus invalid field
      const nextInvalidField = moveNextGenerator(lastInvalidField, true);
      moveFocus(nextInvalidField);
    }
  };

  // handle server-side errors
  const setFieldServerErrors = (errors?: FieldServerErrors | null) => {
    if (!errors || isEmptyArray(errors)) {
      return;
    }

    // set field server errors
    errors.forEach((error) => {
      form.setError(
        error.fieldId,
        { message: error.message, type: 'server' },
        { shouldFocus: true },
      );
    });

    // set focus on first invalid field
    focusField(errors[0].fieldId);
  };

  // set field errors and focus on it
  useEffect(() => {
    setFieldServerErrors(fieldErrors);
  }, [fieldErrors, form.control?.fieldsRef?.current]);

  const locator = useMemo(
    () => createLocator(wizard, stepId || '', locators),
    [wizard, stepId, locators],
  );

  // handle submit button
  const formValidSubmitHandler = (data: FieldDataObject) => {
    // TODO: implement a centralized way to disable user interaction with fields during submitting the form
    if (document.activeElement) {
      const activeElement = document.activeElement as HTMLElement;
      activeElement.blur();
    }

    onSubmit({
      setFieldErrors: setFieldServerErrors,
      setTerminationStepAction: onTerminationStep,
      data: serializeResult(data),
    });
  };

  const formInvalidSubmitHandler = () => {
    // trying to focus first invalid input after client validation

    // map with errors from state ..
    const invalidFieldIds = form.formState.errors;
    if (!invalidFieldIds || isEmptyObject(invalidFieldIds)) {
      return;
    }

    // set focus on first invalid input
    const firstInvalidFieldName = getFirstInvalidFieldName(invalidFieldIds);
    if (firstInvalidFieldName) {
      focusField(firstInvalidFieldName);
    }
  };

  const conditionalSubmitHandler = (contextData?: Record<string, unknown>) => {
    blurActiveElement();

    if (customSubmit || isCustomSubmit?.({
      contextData,
      formState: form.formState,
    })) {
      onSubmit({
        contextData,
        setFieldErrors: () => {},
        data: serializeResult(form.getValues()),
      });
    } else {
      form.handleSubmit(formValidSubmitHandler, formInvalidSubmitHandler)();
    }
  };

  const formSubmitHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    conditionalSubmitHandler();
  };

  const fieldsRender = useMemo(() => (
    fields.map((f: Field, i) => {
      const Component = locator(f);
      const uiProps = fieldsUiProps?.get(Component);

      return (
        <Component
          key={f.id || i}
          parent=""
          field={f}
          locator={locator}
          fieldUiProps={uiProps}
        />
      );
    })
  ), [fields, locator, fieldsUiProps]);

  const buttonsRender = useMemo(() => buttons?.({
    form,
    submit: conditionalSubmitHandler,
    back: onBack,
    cancel: onCancel,
  }), [buttons, form, conditionalSubmitHandler, onBack, onCancel]);

  return {
    form,
    submitHandler: formSubmitHandler,
    fieldsRender,
    buttonsRender,
  };
};
