import React, { FC } from 'react';

import classNames from 'classnames';

import { Loader, LoaderSize } from '@ast/magma/components/loader';

import { OverlayWithMask } from '../Overlay/OverlayWithMask';

export type OverlaySpinnerProps = Partial<React.ComponentProps<typeof OverlayWithMask>> & {
  readonly visible: boolean;
  /**
   * Spinner size.
   */
  readonly size?: LoaderSize;
  readonly className?: string;
};

export const OverlaySpinner: FC<OverlaySpinnerProps> = (
  props,
) => {
  const {
    className,
    visible,
    size,
    ...overlayProps
  } = props;

  return !visible
    ? null
    : (
      <OverlayWithMask
        className={classNames(className)}
        align="center"
        data-stable-name="OverlaySpinner"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...overlayProps}
      >
        <Loader size={size} />
      </OverlayWithMask>
    );
};

OverlaySpinner.defaultProps = {
  visible: false,
  size: 'md',
  position: 'absolute',
};
