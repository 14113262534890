const SEPARATOR = '.';

/** Combine names and return merged field name */
export const combineNames = (...parts: string[]) => (
  parts
    .filter((part) => part !== '')
    .reduce((prev, next) => prev ? `${prev}${SEPARATOR}${next}` : next, ''))

/** Uncombine field name and return its parts */
export const uncombineNames = (name: string) => (
  name.split(SEPARATOR)
);
