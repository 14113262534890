import { PlatformKind } from '@app/queryTyping';

import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

/**
 * Detects the current platform.
 */
export function useCurrentPlatform(): PlatformKind {
  // This check is used for the compatibility as it maps `tablet` devices to the `desktop` platform.
  const isMobile = useIsMobile();

  if (isMobile) {
    return PlatformKind.MOBILE;
  }

  return PlatformKind.DESKTOP;
}
