import { CarouselSlide } from '@app/common/components/Carousel/types';

import { ReadyZoneWidget } from '../../types';
import { getWidgetWidth } from '../../utils/getWidgetWidth';

/**
 * Creates carousel slides from the widgets.
 */
export function makeCarouselSlides(widgets: ReadyZoneWidget[]): CarouselSlide[] {
  return widgets.map((widget) => ({
    element: widget.element,
    width: getWidgetWidth(widget.type),
  }));
}
