import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import Warning from '@ast/magma/components/icon/icons/Warning';

import { Button } from '@ast/magma/components/button';
import { Card } from '@ast/magma/components/card';
import { DialogStatus } from '@ast/magma/components/dialog';

import { uniqueId } from '@ast/magma/inner/utils/uniqueid';

import { MetaInfo } from '@app/common/components/ErrorDialog/MetaInfo/MetaInfo';
import { ErrorTypes } from '@app/common/errors/errorProcessors/constants';
import { UserError } from '@app/common/errors/UserError';
import { isHostedInFrame } from '@app/common/utils/isHostedInFrame/isHostedInFrame';

import styles from './ErrorCard.pcss';

/**
 * Error card properties.
 */
export interface ErrorCardProps {
  /**
   * User facing error.
   */
  readonly error: UserError;
  /**
   * Custom error footer component.
   */
  readonly footer: React.ReactNode;
}

type GetStylesProps = { isMobile: boolean, mobileAndFrame: string, mobile: string, desktop: string };
const getStyles = ({
  isMobile, mobileAndFrame, mobile, desktop,
}: GetStylesProps) => {
  const hostedInFrame = isHostedInFrame();

  if (isMobile) {
    if (hostedInFrame) {
      return mobileAndFrame;
    }
    return mobile;
  }

  return desktop;
};

/**
 * Error card component to show user facing error inside parent container.
 * @param props
 */
export const ErrorCard = (props: ErrorCardProps) => {
  const {
    error,
    footer,
  } = props;

  const { t } = useTranslation();

  const cardTitleId = useMemo(() => uniqueId('error-title'), []);
  const cardContentId = useMemo(() => uniqueId('error-content'), []);
  const detailsId = useMemo(() => uniqueId('error-details'), []);
  const isMobile = useBreakpoint().device === 'mobile';
  const [detailsShown, setDetailsShown] = useState(false);
  const isMetaShown = error.type === ErrorTypes.InternalServerError || error.type === ErrorTypes.ApplicationError;

  return (
    <div
      className={classNames(
        styles.overlay,
        getStyles({
          isMobile,
          desktop: styles.centerAlign,
          mobile: styles.bottomAlign,
          mobileAndFrame: styles.noAlign,
        }),
      )}
    >
      <Card
        role="alert"
        aria-labelledby={cardTitleId}
        aria-describedby={cardContentId}
        size="dialog"
        className={classNames(
          styles.dialog,
          getStyles({
            isMobile,
            desktop: styles.dialogDesktop,
            mobile: styles.dialogMobile,
            mobileAndFrame: styles.dialogMobileAndFrame,
          }),
        )}
        hasShadow={!isMobile}
        hasBorder={false}
      >
        <DialogStatus
          className={styles.status}
          icon={<Warning />}
          level="info"
        />

        {error.title && (
        <h5 id={cardTitleId} className={styles.title}>{error.title}</h5>
        )}

        {error.message && (
        <div id={cardContentId} className={styles.message}>{error.message}</div>
        )}

        {error.details && (
        <>
          <Button
            look="inlinetext"
            className={styles.toggleDetailsButton}
            aria-expanded={detailsShown}
            aria-controls={detailsId}
            onClick={(e) => {
              // prevent url changing
              e.preventDefault();
              setDetailsShown(!detailsShown);
            }}
            data-stable-name="ToggleDetailsButton"
          >
            {detailsShown
              ? t('error-card.hide-details-link.text|Represent the text link to hide the error details', 'Hide details')
              : t('error-card.show-details-link.text|Represent the text link to show the error details', 'Show details')}
          </Button>

          {detailsShown && (
          <pre
            id={detailsId}
            className={styles.details}
            data-stable-name="Details"
          >
            {error.details}
          </pre>
          )}
        </>
        )}
        {footer}
        {isMetaShown && <MetaInfo />}
      </Card>
    </div>
  );
};
