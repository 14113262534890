import React, { useMemo } from 'react';

import { ColorTheme } from '@app/common/types/ColorTheme';
import { GetIcon } from '@app/common/utils/iconsProvider';

import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';

/**
 * Themed icon properties.
 */
export interface ThemedIconProps {
  readonly className?:string;
  /**
   * Icon source for light theme.
   */
  readonly lightThemeIconPath:string;
  /**
   * Icon source for dark theme.
   */
  readonly darkThemeIconPath:string;
  /**
   * Icon provider to dynamically get icons.
   */
  readonly iconProvider:GetIcon;
}

/**
 * Icon image which support separate src for light and dark theme.
 */
export const ThemedIcon = (props:ThemedIconProps) => {
  const {
    className,
    lightThemeIconPath,
    darkThemeIconPath,
    iconProvider,
  } = props;
  // Read current color theme.
  const { colorTheme } = useColorThemeContext();

  return (
    <>
      {
        (colorTheme === ColorTheme.light)
          ? iconProvider(lightThemeIconPath, className)
          : iconProvider(darkThemeIconPath, className)
      }
    </>
  );
};
