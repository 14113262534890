import React, { useEffect, useRef } from 'react';

import { Alert } from '@ast/magma/components/alert';

import { useWizardStepStateContext } from '../WizardStateStateContext';

export const WizardStepAlert: React.FC = () => {
  const alertRef = useRef<HTMLDivElement>(null);
  const { alertMessage } = useWizardStepStateContext();

  // scroll to the alert message when it appears
  useEffect(() => {
    if (alertMessage && alertRef?.current) {
      alertRef?.current.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [alertMessage]);

  if (!alertMessage) {
    return null;
  }

  return (
    <Alert
      // eslint-disable-next-line i18next/no-literal-string
      level={alertMessage.type || 'info'}
      title={alertMessage.title || ''}
      data-stable-name="WizardAlert"
    >
      {alertMessage.content}
    </Alert>
  );
};
