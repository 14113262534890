import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Type of widget that is compatible with the widget carousel.
 */
export type CarouselCompatibleWidgetType = WidgetType & {
  useCarousel: boolean;
};

/**
 * Checks if the given widget type is compatible with the widget carousel.
 */
export function isCarouselCompatibleWidgetType(type: WidgetType): type is CarouselCompatibleWidgetType {
  return (
    'useCarousel' in type
    && typeof type.useCarousel === 'boolean'
  );
}
