import React, { forwardRef, useMemo } from 'react';

import classNames from 'classnames';

import { CarouselSlide } from '../../types';
import { collectSlideTemplateColumns } from '../../utils/collectSlideTemplateColumns';

import styles from './Slides.pcss';

/**
 * {@link Slides} component properties.
 */
export type SlidesProps = Readonly<{
  /**
   * Carousel slides to render.
   */
  slides: CarouselSlide[];
}>;

/**
 * Carousel slides component that renders the slides.
 */
export const Slides = forwardRef<HTMLDivElement, SlidesProps>(({ slides }, ref) => {
  const slidesContainerClass = classNames(styles.slidesContainer, {
    [styles.slidesCenter]: slides.length <= 1,
    [styles.slidesNormal]: slides.length > 1,
  });

  // Merge slide widths into a single string for the slide gird columns template.
  // This allows to effectively use the grid layout for pixel and percentage slide widths.
  const slideWidths = useMemo(() => collectSlideTemplateColumns(slides), [slides]);

  return (
    <div
      className={slidesContainerClass}
      ref={ref}
      style={{ gridTemplateColumns: slideWidths }}
    >
      {slides.map((slide) => (
        <div
          key={slide.element?.key}
          className={classNames(styles.slide, {
            [styles.slideNoPadding]: slides.length <= 1,
          })}
        >
          {slide.element}
        </div>
      ))}
    </div>
  );
});
