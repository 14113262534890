import { Result } from '../types';

/**
 * Tries to parse a string into an URL object.
 *
 * Returns a parse result containing either a parsed URL or an error.
 */
export function tryParseURL(url: string, base?: string): Result<URL> {
  try {
    const value = new URL(url, base);
    return {
      type: 'success',
      value,
    };
  } catch (error) {
    return {
      type: 'error',
      error,
    };
  }
}
