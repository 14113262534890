import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Returns the width of the widget matching the type or `undefined` if the widget does not have a defined width.
 */
export function getWidgetWidth(widget: WidgetType): string | undefined {
  // eslint-disable-next-line no-underscore-dangle
  switch (widget.__typename) {
    case 'CustomAppWidget':
    case 'SsoWidget':
      return widget.width ?? undefined;
    default:
      return undefined;
  }
}
