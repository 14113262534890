import React, {
  CSSProperties,
  Ref, useEffect, useMemo,
} from 'react';

import classNames from 'classnames';

import Day from '@ast/magma/components/icon/icons/Day';
import Night from '@ast/magma/components/icon/icons/Night';
import SignOut from '@ast/magma/components/icon/icons/SignOut';
import UserCircle from '@ast/magma/components/icon/icons/UserCircle';

import { Accordion, AccordionSelectEventArguments } from '@ast/magma/components/accordion';
import { Button } from '@ast/magma/components/button';
import { Card } from '@ast/magma/components/card';
import { disableBodyScroll, enableBodyScroll } from '@ast/magma/components/modal/scrollmanager';
import { Loader } from '@ast/magma/components/loader';

import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { ColorTheme } from '@app/common/types/ColorTheme';
import { LinkMenuItem, MenuItem, useMainMenuQuery } from '@app/common/types/useMainMenuQuery';

import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';
import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { useSelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';
import { useTranslation } from '@app/core/i18n';
import { PageURL } from '@app/core/widgets/pages';

import { MobileMenuItemRenderer } from './mobilemenuitem/mobilemenuitem';

import styles from './mobilemenu.pcss';

export interface MobileMenuProps {
  readonly onClose:(item?:LinkMenuItem)=>void;
  readonly onSignOut:()=>void;
  readonly style?: CSSProperties;
}

export const MobileMenu = React.forwardRef(
  (props:MobileMenuProps,
    ref: Ref<HTMLDivElement>) => {
    const { t } = useTranslation('footer');
    const {
      loading, error, categories,
    } = useMainMenuQuery();
    const selectedMainMenuItem = useSelectedMainMenuItemContext();
    const currentUserInfo = useCurrentUserInfoContext();
    const { colorTheme, changeColorTheme } = useColorThemeContext();

    if (error) {
      throw error;
    }

    /**
     * Disable body scrolling while mobile menu is opened.
     * This code similar to modal dialogs.
     */
    useEffect(() => {
      disableBodyScroll();
      return () => {
        enableBodyScroll();
      };
    }, []);

    const menuGroups = useMemo(() => {
      let result:MenuItem[] = [];
      if (categories != null) {
        result = categories.reduce((groups, category) => {
          groups.splice(groups.length, 0, ...category.menuItems!);
          return groups;
        }, result);
      }

      return result;
    }, [categories]);

    // Find highlighted group based on match any menu item link with current URL
    const highlightedGroupIndex = useMemo(() => (menuGroups?.findIndex(
      (menuGroup:MenuItem) => menuGroup.menuItems?.some((item:LinkMenuItem) => selectedMainMenuItem?.url === item.url),
    )), [menuGroups]);

    const handleSelection = (event: AccordionSelectEventArguments) => {
      if (event.action === 'select') {
        props.onClose(event.data as LinkMenuItem);
      }
    };

    const toggleColorTheme = () => {
      changeColorTheme(colorTheme === ColorTheme.light
        ? ColorTheme.dark
        : ColorTheme.light);
    };

    return (
      <Card
        style={props.style}
        ref={ref}
        hasShadow={false}
        hasBorder={false}
        className={classNames(styles.menu)}
      >
        <div className={styles.scrollContainer}>
          <div className={styles.profile}>
            <h6 className={styles.label}>{currentUserInfo?.preferredName}</h6>
            <Button
              look="inlinetext"
              icon={<UserCircle />}
              className={styles.profileButton}
              data-stable-name="ProfileButton"
              onClick={() => {
                props.onClose({ url: `${PageURL.CONTACTS_AND_SETTINGS}/profile-security/` } as LinkMenuItem);
              }}
            >
              {t('open-account-setting|Open account settings', 'See your profile')}
            </Button>
            <LocalizationSelectorWrapper preferredCulture={currentUserInfo?.preferredCulture} />
            <Button
              look="inlinetext"
              icon={(colorTheme === ColorTheme.light) ? <Night /> : <Day />}
              className={styles.profileButton}
              data-stable-name="ThemeToggleButton"
              onClick={toggleColorTheme}
            >
              {(colorTheme === ColorTheme.light)
                ? t('mobile-user-bar.theme.dark.enabled|Message for enabling dark theme in Mobile theme', 'Switch to dark theme')
                : t('mobile-user-bar.theme.light.enabled|Message for enabling light theme in Mobile theme', 'Switch to light theme')}
            </Button>
            <Button
              look="inlinetext"
              icon={<SignOut />}
              className={styles.profileButton}
              data-stable-name="SignOutButton"
              onClick={(event) => {
                event.preventDefault();
                props.onSignOut();
              }}
            >
              {t('signout-setting|Sign out', 'Sign out')}
            </Button>
          </div>
          {
            (loading)
              ? (<Loader size="xl" />)
              : (
                <Accordion
                  expanded={highlightedGroupIndex >= 0 ? [`.${highlightedGroupIndex}`] : undefined}
                  expandMode="multiple"
                  data={menuGroups}
                  className={styles.accordion}
                  // eslint-disable-next-line i18next/no-literal-string
                  childField="menuItems"
                  onSelect={handleSelection}
                  itemRenderer={MobileMenuItemRenderer}
                />
              )
          }
        </div>
      </Card>
    );
  },
);
