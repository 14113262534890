import React, { useCallback, useReducer, useState } from 'react';

import { SubmitWizardStepParams } from '@app/queryTyping';

import { WizardStepMediator } from '@app/common/components/configurable-wizards/WizardStepMediator';
import { OverlaySpinner } from '@app/common/components/overlayspinner';
import {
  DEFAULT_WIZARD_STATE,
  setWizardStepAlertMessage,
  setWizardStepFieldsErrors,
  setWizardStepGenericErrors,
  setWizardStepLoading,
  setWizardStepState,
  setWizardStepUnhandledErrors,
  stepStateReducer,
  WizardStepLocatorsMap,
  WizardStepSubmitHandler,
} from '@app/common/configurable-wizards';
import { useGetWizardStepQuery } from '@app/common/configurable-wizards/hooks/useGetWizardStepQuery';
import { usePostWizardStepMutation } from '@app/common/configurable-wizards/hooks/usePostWizardStepMutation';
import { WizardStepStateDispatchProvider } from '@app/common/configurable-wizards/state/WizardStateDispatchContext';
import { isNextStep, isHasErrors, isMovePreviousResult } from '@app/common/configurable-wizards/utils';
import { WizardStepStateProvider } from '@app/common/configurable-wizards/WizardStateStateContext';
import { useDocumentTitle } from '@app/common/utils/hooks/useDocumentTitle';
import { getServerErrors } from '@app/common/utils/serverValidation';

import { TriggeredEventWizardBase } from '../types';
import { useTriggeredEventsTranslation } from '../useTriggeredEventsTranslation';

import { useGetTriggeredEventStepQuery } from '../queries/queryTyping/get-wizard-step';
import { useMovePreviousTriggeredEventStepMutation } from '../queries/queryTyping/move-to-previous-wizard-step';
import { usePostTriggeredEventStepMutation } from '../queries/queryTyping/post-wizard-step';

import styles from './TriggeredEventWizard.pcss';

export interface TriggeredEventWizardProps extends TriggeredEventWizardBase {
  readonly steps: WizardStepLocatorsMap;
  readonly wizard: string;
}

export const TriggeredEventWizard: React.FC<TriggeredEventWizardProps> = ({
  onResolved,
  onCancel,
  challengeId,
  steps,
  wizard,
}) => {
  const { t } = useTriggeredEventsTranslation();
  const [stepId, setStepId] = useState<string>('');
  const [state, dispatch] = useReducer(stepStateReducer, DEFAULT_WIZARD_STATE);

  // set current step name to the document title
  useDocumentTitle(
    state.name ? t(
      'document.step-title|Document title of the triggered event challenge steps, showing in the browser tab',
      '{{stepName}} | Triggered event challenge',
      { stepName: state.name },
    ) : t(
      'document.default.step-title|Default document title of the triggered event challenge, showing in the browser tab',
      'Triggered event challenge',
    ),
    false,
    false,
  );

  const { loading: isLoadingStep } = useGetWizardStepQuery(
    useGetTriggeredEventStepQuery,
    {
      variables: {
        stateParams: {
          challengeId,
          stepId,
        },
      },
    },
    dispatch,
  );

  const isLoading = isLoadingStep || state.loading;

  const [submit] = usePostWizardStepMutation(
    usePostTriggeredEventStepMutation,
    {
      onCompleted: ({ triggeredEventStep }) => {
        const result = triggeredEventStep!;

        if (isNextStep(result)) {
          if (!isHasErrors(result)) {
            setStepId(result.nextStepId);
          }
        } else if (result.wizardFinished) {
          onResolved();
        }
      },
    },
    dispatch,
  );

  const submitStep: WizardStepSubmitHandler = useCallback(
    ({ data }) => {
      dispatch(setWizardStepAlertMessage(null));

      submit({
        variables: {
          stepParams: {
            challengeId,
            isTerminationStep: state.isTermination,
            stepId: state.id!,
            stepData: data as SubmitWizardStepParams['stepData'],
          },
        },
      });
    },
    [state, state.id],
  );

  /**
   * @todo: move repetative logic to generic helper functions
   */
  const [back] = useMovePreviousTriggeredEventStepMutation(
    {
      onCompleted: ({ movePreviousTriggeredEventStep }) => {
        dispatch(setWizardStepLoading(false));
        const result = movePreviousTriggeredEventStep!;

        if (isMovePreviousResult(result)) {
          // show validation errors
          if (isHasErrors(result)) {
            const { fieldErrors, genericErrors } = getServerErrors(result.errors);

            if (fieldErrors) {
              dispatch(setWizardStepFieldsErrors(fieldErrors));
            }

            if (genericErrors) {
              dispatch(setWizardStepGenericErrors(genericErrors));
            }
          } else if (result.isMoved) {
            dispatch(setWizardStepState(DEFAULT_WIZARD_STATE));
            setStepId(result.currentStepId);
          }
        }
      },
      onError: (error) => {
        dispatch(setWizardStepLoading(false));
        dispatch(setWizardStepUnhandledErrors([error]));
      },
    },
  );

  const onBack = useCallback(() => {
    dispatch(setWizardStepLoading(true));
    back({
      variables: {
        stepParams: {
          challengeId,
        },
      },
    });
  }, [back, challengeId]);

  return (
    <WizardStepStateDispatchProvider value={dispatch}>
      <WizardStepStateProvider value={state}>
        <OverlaySpinner
          visible={isLoading}
          position="absolute"
          align="center"
          containerClassName={styles.overlay}
        />
        <WizardStepMediator
          steps={steps}
          wizardStepProps={{
            onSubmit: submitStep,
            onCancel,
            onBack,
            wizard,
          }}
        />
      </WizardStepStateProvider>
    </WizardStepStateDispatchProvider>
  );
};
