import { RetrievalType, UserKind } from '@app/queryTyping';

import { WizardConstants } from '@app/common/configurable-wizards';

export enum WizardSteps {
  IdentityEstablishment = 'IdentityEstablishment',
  IdentityVerification = 'IdentityVerification',
  PasswordReset = 'PasswordReset',
  UserEventLogs = 'UserEventLogs',
  // UI only step
  RetrievalResult = 'RetrievalResult',
  ForcePasswordReset = 'ForcePasswordReset',
}

export const Constants: WizardConstants<WizardSteps> = {
  WIZARD_NAME: 'UserRetrieval',
  WIZARD_DEFAULT_STEP: WizardSteps.IdentityEstablishment,
};

export interface RetrievalState {
  readonly retrievalType?: RetrievalType;
  readonly userKind?: UserKind;
  readonly showForcePasswordResetAlert?: Boolean;
}
