import React from 'react';

import classNames from 'classnames';

import SmallAngleRight from '@ast/magma/components/icon/icons/SmallAngleRight';

import { TextLink } from '@ast/magma/components/textlink';

import { isTetheredLogin } from '@app/core/components/TetheredLoginApplication';

import styles from './LoginLink.pcss';

export interface LoginLinkProps { }

/**
 * Wraps TextLink component to open links in a new window in tethered mode
 */
export const LoginLink: React.FC<Omit<React.ComponentProps<typeof TextLink>, 'target'>> = ({
  onClick,
  className,
  children,
  ...linkProps
}) => (
  <TextLink
    {...linkProps}
    target={isTetheredLogin(window.location.pathname) ? '_parent' : undefined}
    className={
        classNames(
          styles.link,
          className,
        )
      }
  >
    <span className={styles.linkText}>{children}</span>
    <SmallAngleRight className={styles.linkIcon} />
  </TextLink>
);
