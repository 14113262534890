import React, {
  FC,
  ReactNode, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import ArrowLeft from '@ast/magma/components/icon/icons/ArrowLeft';

import { Button } from '@ast/magma/components/button';

import { HorizontalBannerWidgetZone } from '@app/common/components/HorizontalBannerWidgetZone/HorizontalBannerWidgetZone';
import { SectionHeader } from '@app/common/components/SectionHeader/SectionHeader';
import {
  VerticalBannerWidgetZone,
} from '@app/common/components/VerticalBannerWidgetZone/VerticalBannerWidgetZone';

import { ZoneName } from '@app/core/components/WidgetZone/constants';
import { useTranslation } from '@app/core/i18n';

import { FullscreenLayoutHorizontalZone } from './FullscreenLayoutHorizontalZone/FullscreenLayoutHorizontalZone';

import styles from './FullscreenLayout.pcss';

export interface FullScreenLayoutProps {
  readonly children: ReactNode;
  readonly title?: string;
  readonly backRoute?: string | (() => void) | null;
  /**
   * Hides header section and leaves a blank space on top in MO
  */
  readonly hideHeaderSection?: boolean;
  /**
   * Completely removes header section including a blank space on top in MO
  */
  readonly removeHeaderSection?: boolean;
  readonly backButtonAriaLabel?: string;
  readonly headerSectionRightComponent?: React.ReactElement,
}

export const FullscreenLayout: FC<FullScreenLayoutProps> = ({
  title,
  children,
  backRoute,
  hideHeaderSection,
  removeHeaderSection,
  backButtonAriaLabel,
  headerSectionRightComponent,
}) => {
  const isMobile = useBreakpoint().device === 'mobile';
  const isPortrait = useBreakpoint().orientation === 'portrait';
  const history = useHistory();
  const { t } = useTranslation();
  const [withBackgroundImage, setWithBackgroundImage] = useState<boolean>(false);

  const defaultBackAriaLabel = t('go-back-label|Title for back button', 'Go back');

  // Indicates, if header section should be presented
  const withHeaderSection = !hideHeaderSection && !removeHeaderSection;

  // Raise with backround image flag
  useEffect(() => {
    const main = document.querySelector('main');
    if (main) {
      setWithBackgroundImage(getComputedStyle(main, ':before').backgroundImage !== 'none');
    }
  }, []);

  return (
    <div className={classNames(
      styles.layout,
      withBackgroundImage && styles.withBackgroundImage,
      removeHeaderSection && styles.withoutHeaderSection,
    )}
    >
      { (!isMobile || !backRoute) && (
        <FullscreenLayoutHorizontalZone
          className={styles.bannerZone}
          zoneName={ZoneName.HeaderBanner}
        />
      )}
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.leftPadding)} />
        <div className={styles.horizontal}>
          <div className={styles.vertical}>
            {withHeaderSection && (
            <SectionHeader
              className={styles.header}
              before={backRoute && (
              <Button
                data-stable-name="BackButton"
                className={styles.backButton}
                look="icon"
                icon={<ArrowLeft />}
                aria-label={backButtonAriaLabel || defaultBackAriaLabel}
                onClick={() => {
                  if (typeof backRoute === 'string') {
                    history.push(backRoute);
                  } else if (typeof backRoute === 'function') {
                    backRoute();
                  }
                }}
              />
              )}
              after={headerSectionRightComponent && (headerSectionRightComponent)}
            >
              {title}
            </SectionHeader>
            )}
            <section className={styles.content}>
              <HorizontalBannerWidgetZone
                className={styles.bannerZone}
                zoneName={ZoneName.PreMiddle}
              />
              {children}
              <HorizontalBannerWidgetZone
                className={styles.bannerZone}
                zoneName={ZoneName.PostMiddle}
              />
            </section>
          </div>
        </div>
        { !(isMobile || isPortrait) && <VerticalBannerWidgetZone /> }
        <div className={classNames(styles.rightPadding)} />
      </div>
      { (!isMobile || !backRoute) && (
        <FullscreenLayoutHorizontalZone
          className={styles.bannerZone}
          zoneName={ZoneName.FooterBanner}
        />
      )}
    </div>
  );
};
