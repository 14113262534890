import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import AngleDown from '@ast/magma/components/icon/icons/AngleDown';

import { Loader } from '@ast/magma/components/loader';

import {
  findChildIndex,
  findNthFocusableChild,
  moveNextChildGenerator,
  moveFocus,
  movePrevChildGenerator,
} from '@ast/magma/utils/focus';
import useForwardedRef from '@ast/magma/utils/useForwardedRef';
import { useMouseDownOutside } from '@ast/magma/utils/useMouseDownOutside/useMouseDownOutside';

import {
  LinkMenuItem,
  MenuItem,
  useMainMenuQuery,
} from '@app/common/types/useMainMenuQuery';
import { useIFrameFocusOut } from '@app/common/utils/hooks/useIFrameFocusOut';
import { getMenuIcon } from '@app/common/utils/iconsProvider';
import { isRelativeURL } from '@app/common/utils/url';

import { MenuItemEligibility } from '@app/core/components/MenuNavigation/MenuItemEligibility';
import { SelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';

import { MainNavigationPopup } from './mainnavpopup/mainnavpopup';
import { MainNavSection } from './mainnavsection/mainnavsection';
import { Tab } from './tabs/tab/tab';
import { Tabs } from './tabs/tabs';

import styles from './mainmenu.pcss';

/**
 * Main menu properties.
 */
export interface MainMenuProps {
  /**
   * Selected tab index.
   */
  readonly selectedTabIndex?:number;
}

export const isItemVisible = (menuItem: MenuItem) => !menuItem.menuItems?.filter(
  (item) => {
    const length = item?.menuItems?.filter((subItem) => subItem.isVisible).length;
    return length && length > 0;
  },
).length;

/**
 * Main menu component.
 * Show button bar on top by default with popup sections under each item.
 * Popup sections contain grouped link items.
 */
export const MainMenu = React.forwardRef((
  props: MainMenuProps,
  menuRef: React.Ref<HTMLElement>,
) => {
  const { selectedTabIndex: outerSelectedTabIndex } = props;
  const tabsRef = useForwardedRef<HTMLElement>(menuRef);
  const history = useHistory();
  const mainNavRef = useRef<HTMLDivElement>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<undefined | number>(
    outerSelectedTabIndex,
  );
  const location = useLocation();
  const { loading, error, categories } = useMainMenuQuery();

  const menuId = 'dashboard-main-menu';
  /**
   * Update selected index if set intentionally
   */
  useEffect(() => {
    if (outerSelectedTabIndex !== selectedTabIndex) {
      setSelectedTabIndex(outerSelectedTabIndex);
    }
  }, [outerSelectedTabIndex]);

  const mainNavCloseHandler = useCallback(() => {
    if (selectedTabIndex != null) {
      // drop selected tab index
      setSelectedTabIndex(undefined);
    }
  }, [selectedTabIndex]);

  // On mouse down outside main menu
  useMouseDownOutside(tabsRef, () => {
    mainNavCloseHandler();
  }, [selectedTabIndex]);

  useIFrameFocusOut(() => {
    mainNavCloseHandler();
  }, [selectedTabIndex]);

  // Find highlighted tab based on match any menu item link with current URL
  const highlightedTab = useMemo(() => (categories?.find(
    (tabData:LinkMenuItem) => tabData.menuItems?.some((section) => (
      section.menuItems?.some((item:LinkMenuItem) => item.url && location.pathname.startsWith(item.url)))),
  )), [categories, location.pathname]);

  const tabKeyDownHandler = useCallback((event:React.KeyboardEvent<HTMLElement>) => {
    if (event.isDefaultPrevented()) return;

    if (event.key === 'Escape') {
      if (selectedTabIndex != null) {
        mainNavCloseHandler();
        event.preventDefault();
      }
    }
  }, [selectedTabIndex]);

  /**
   * Menu section keyDown handler.
   * @param event keydown event.
   */
  const keyDownHandler = useCallback((event:React.KeyboardEvent<HTMLDivElement>) => {
    if (event.isDefaultPrevented()) return;

    // Move focus to next right section
    if (event.key === 'ArrowRight') {
      const activeIndex = findChildIndex(document.activeElement);
      const nextChild = findNthFocusableChild(event.currentTarget.nextElementSibling, activeIndex);
      if (nextChild) {
        nextChild.focus();
        event.preventDefault();
      }
    // Move focus to prev left section
    } else if (event.key === 'ArrowLeft') {
      const activeIndex = findChildIndex(document.activeElement);
      // eslint-disable-next-line max-len
      const prevChild = findNthFocusableChild(event.currentTarget.previousElementSibling, activeIndex);
      if (prevChild) {
        prevChild.focus();
        event.preventDefault();
      }
    // Close selected tab popup
    } else if (event.key === 'Escape') {
      if (selectedTabIndex != null) {
        mainNavCloseHandler();
        event.preventDefault();
      }
    // Move focus to last item of prev section
    } else if (event.key === 'ArrowUp') {
      if (moveFocus(movePrevChildGenerator(event.currentTarget.previousElementSibling, false))) {
        event.preventDefault();
      }
    // Move focus to first item of next section
    } else if (event.key === 'ArrowDown') {
      if (moveFocus(moveNextChildGenerator(event.currentTarget.nextElementSibling, false))) {
        event.preventDefault();
      }
    }
  }, [selectedTabIndex]);

  /**
   * Handle menu item selection.
   * @param selected selected menu item.
   */
  const handleSelectedLink = useCallback((selected:LinkMenuItem) => {
    // Close popup panel
    mainNavCloseHandler();
    // Redirect page to selected URL
    if (selected.url) {
      // Open relative path via react router
      if (isRelativeURL(selected.url)) {
        history.push(selected.url);
      } else {
        // Open absolute path via window
        window.open(selected.url);
      }
    }
  }, [selectedTabIndex]);

  if (loading) {
    return (
      <div className={styles.spinner}>
        <Loader size="sm" />
      </div>
    );
  }

  if (error) {
    throw error;
  }

  return (
    <SelectedMainMenuItemContext>
      <Tabs
        ref={tabsRef}
        current={selectedTabIndex}
        onChange={setSelectedTabIndex}
      >
        {
          categories && categories.map((tabData:MenuItem, index) => (
            <MenuItemEligibility
              // eslint-disable-next-line react/no-array-index-key
              key={`item-${tabData.id}-${index}`}
              menuItem={tabData}
            >
              <Tab
                data-test-id={tabData.id}
                index={index}
                id={tabData.id}
                onKeyDown={tabKeyDownHandler}
                key={`tab-${tabData.id}`}
                iconBefore={tabData.icon ? getMenuIcon(tabData.icon) : undefined}
                iconAfter={<AngleDown />}
                label={tabData.linkName}
                data-stable-name={tabData.id}
                tabBtnClassName={classNames(
                  styles.tabButton,
                  // highlight tab when no selected tab
                  isItemVisible(tabData) && styles.hiddenTab,
                  (
                    (highlightedTab === tabData && selectedTabIndex == null)
                    || selectedTabIndex === index
                  ) && styles.selectedTab,
                )}
                tabPanelClassName={styles.tabPanel}
              >
                <MainNavigationPopup
                  ref={mainNavRef}
                  onClose={mainNavCloseHandler}
                >

                  { tabData?.menuItems?.map((section: MenuItem, sectionIndex: number) => (
                    <MainNavSection
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${tabData.id}-${sectionIndex}`}
                      menuId={menuId}
                      id={section.id}
                      onKeyDown={keyDownHandler}
                      linkName={`${section.linkName}`}
                      icon={section.icon}
                      menuItems={section.menuItems}
                      onSelectLink={handleSelectedLink}
                      data-stable-name="MainNavSection"
                    />
                  ))}
                </MainNavigationPopup>
              </Tab>
            </MenuItemEligibility>
          ))
        }
      </Tabs>
    </SelectedMainMenuItemContext>
  );
});
