import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { Badge } from '@ast/magma/components/badge';
import { MenuItem } from '@ast/magma/components/menu';

import ActionUtils from '@ast/magma/inner/utils/action';

import { isTruthy } from '@app/common/utils/assertion';

import { AsyncNotification } from '@app/core/asyncNotifications/utils/messagesReducer';
import { useTranslation } from '@app/core/i18n';

import styles from './MessageNotificationMenuItem.pcss';

export type MenuItemProps = {
  readonly key: string;
  readonly title: string;
  readonly icon: ReactElement;
  readonly route: string;
};
export interface MessageNotificationMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly onSelect?: (data: any) => void;
  readonly data: AsyncNotification;
  readonly item: MenuItemProps;
}

export const MessageNotificationMenuItem: React.FC<MessageNotificationMenuItemProps> = ({
  data,
  item,
  onSelect,
  onClick,
  onKeyUp,
  ...props
}) => {
  const { t } = useTranslation();
  const messagesCount = data[item.key as keyof AsyncNotification];
  const hasMessages = messagesCount > 0;

  return (
    <MenuItem
      data={item.route}
      getItemLabel={() => item.title}
      getItemIcon={() => item.icon}
      data-stable-name={`Notification${item.key}Item`}
      className={classNames(hasMessages && styles.hasMessages)}
      {...ActionUtils.handleSelect(
        () => (onSelect && !isTruthy(props['aria-disabled']) && onSelect(item)),
        onClick,
        onKeyUp,
      )}
      {...props}
    >
      <Badge
        aria-label={t(
          'message-notification-menu.messages-count-badge.aria|Unread messages counter badge',
          'You have {{number}} unread {{items}}', { number: messagesCount, items: item.title },
        )}
        color="primary"
        display="filled"
        count={messagesCount}
      />
    </MenuItem>
  );
};

MessageNotificationMenuItem.defaultProps = {
  role: 'menuitem',
};
