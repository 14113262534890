import {
  Field, FieldComponent,
  FieldLocator,
  LocatorResult,
} from '../types';

import { DateFieldLocator } from './Date/DateField';
import { LabelAsPlainTextFieldLocator } from './label/LabelAsPlainText';
import { PasswordFieldLocator } from './Password/Password';
import { PinFieldFieldLocator } from './PIN/PinField';

import { CheckboxFieldLocator } from './CheckboxField';
import { ConstrainedTextFieldLocator } from './ConstrainedTextField';
import { FallbackFieldLocator } from './Fallback';
import { FieldInfoGroupLocator } from './FieldInfoGroup';
import { HiddenFieldLocator } from './Hidden';
import { NotificationFieldLocator } from './Notification';
import { PhoneFieldLocator } from './Phone';
import { TextFieldLocator } from './TextInput';

export const createLocator = (
  wizard: string,
  step: string,
  locators: readonly FieldLocator[] = [],
) => {
  const fieldLocators = [
    // special wizard/step fields
    ...locators,

    // general fields
    LabelAsPlainTextFieldLocator,
    HiddenFieldLocator,
    NotificationFieldLocator,
    PinFieldFieldLocator,
    PhoneFieldLocator,
    PasswordFieldLocator,
    TextFieldLocator,
    ConstrainedTextFieldLocator,
    FieldInfoGroupLocator,
    DateFieldLocator,
    CheckboxFieldLocator,

    // fallback field MUST be the last
    FallbackFieldLocator,
  ];

  return <T extends Field>(field: T): FieldComponent<T> => {
    const component = fieldLocators.reduce<LocatorResult>(
      (s, l) => s || l({
        wizard: {
          name: wizard,
          step,
        },
        field,
      }),
      undefined,
    );
    return component as unknown as FieldComponent<T>;
  };
};
