import { PasskeyOnboardingLocalStorage } from './PasskeyOnboardingLocalStorage';
import { PasskeyOnboardingPresentingProps } from './types';

/**
 * Facade for the passkey onboarding local storage.
 * This keeps the specyfic logic for the passkey onboarding
 * while the PasskeyOnboardingLocalStorage rather concentrates on the storing aspect,
 * abstracted from the business logic.
 */
export class PasskeyOnboardingLocalStorageFacade {
  protected static storage = PasskeyOnboardingLocalStorage.instance;

  /**
   * Increase the skip count for the user.
   */
  public static increaseSkipCount(userId: string) {
    let { skipCount, lastSkip } = this.storage.getUserData(userId) ?? {
      lastSkip: new Date(),
      skipCount: 0,
    };

    skipCount += 1;
    lastSkip = new Date();
    this.storage.setUserData(userId, { lastSkip, skipCount });
  }

  /**
   * Returns the passkey onboarding presenting props for the user.
   */
  public static getData(userId: string): PasskeyOnboardingPresentingProps | undefined {
    const userData = this.storage.getUserData(userId);

    if (!userData) {
      return undefined;
    }

    return {
      lastSkip: userData.lastSkip,
      skipCount: userData.skipCount,
      deferralPeriodInDays: this.storage.deferralPeriodInDays,
    };
  }
}
