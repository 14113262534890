import React, { FC, useState } from 'react';

import classNames from 'classnames';

import AngleDown from '@ast/magma/components/icon/icons/AngleDown';
import AngleUp from '@ast/magma/components/icon/icons/AngleUp';
import Check from '@ast/magma/components/icon/icons/Check';
import Empty from '@ast/magma/components/icon/icons/Empty';
import GlobeIcon from '@ast/magma/components/icon/icons/Globe';

import { DropDownMenu } from '@ast/magma/components/dropdownmenu';
import { Menu, MenuItem } from '@ast/magma/components/menu';

import { Button } from '@ast/magma';

import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { usePagePath } from '@app/core/components/Application/usePagePath';
import { isTetheredLogin } from '@app/core/components/TetheredLoginApplication';
import { getLanguageFromCulture } from '@app/core/contexts/localizationContext/LocalizationContext';
import { useTranslation } from '@app/core/i18n';

import styles from './LocalizationSelector.pcss';

type MenuItemValue = {
  label?: string;
  value?: string;
  getItemIcon?: () => JSX.Element;
};

export interface LocalizationSelectorProps {
  readonly loading: boolean;
  readonly items: MenuItemValue[];
  readonly currentCulture: string | null;
  readonly onCurrentItemChange?: (item: MenuItemValue) => void;
}

export const LocalizationSelector: FC<LocalizationSelectorProps> = ({
  items,
  loading,
  currentCulture,
  onCurrentItemChange,
}) => {
  const { t } = useTranslation('localization-selector');

  const { layoutId } = usePagePath();

  const loggedIn = useIsLoggedIn();
  const isMobile = useIsMobile();

  const isLoggedInDesktop = loggedIn && !isMobile;
  const isLoggedInMobile = loggedIn && isMobile;

  const isTetheredMode = isTetheredLogin(window.location.pathname);
  const isSimpleLayout = layoutId === 'simple';

  const getCultureItem = (code: string | null) => items
    .find((item) => item.value === code || (code && item.value === getLanguageFromCulture(code))) ?? null;

  const [
    selectedItem,
    setSelectedItem,
  ] = useState<MenuItemValue | null>(getCultureItem(currentCulture));

  const [
    isDropDownOpened,
    setIsDropDownOpened,
  ] = useState<boolean>(false);
  const handleItemClick = (item:MenuItemValue): void => {
    if (item.value === selectedItem?.value) {
      return;
    }
    setSelectedItem(item);
    setIsDropDownOpened(false);
    if (onCurrentItemChange) {
      onCurrentItemChange(item);
    }
  };
  const handleOpenChange = (newOpen:boolean) => setIsDropDownOpened(newOpen);

  const getButtonLabel = () => (
    <span className={styles.label}>
      {
        loading
          ? t('localization-selector.loading.label|Button loading label', 'Changing language')
          : selectedItem?.label
      }
    </span>
  );

  return (
    <DropDownMenu
      buttonRenderer={(_, isOpen) => (
        <Button
          look={isMobile ? 'inlinetext' : 'link'}
          iconPosition="start"
          title={t('localization-selector.button.title|Button title', 'Language selector')}
          icon={
            isLoggedInMobile || isLoggedInDesktop || !loggedIn || isSimpleLayout ? (
              <GlobeIcon className={styles.dropDownListButtonIcon} />
            ) : (
              <></>
            )
          }
          className={isMobile ? styles.profileButton : styles.dropDownButton}
        >
          {(isLoggedInMobile || !loggedIn || (isLoggedInDesktop && isSimpleLayout))
            ? (getButtonLabel() as unknown as string)
            : undefined}
          {(!isLoggedInDesktop && !isSimpleLayout)
            && (isOpen ? (
              <AngleUp className={styles.arrow} />
            ) : (
              <AngleDown className={styles.arrow} />
            ))}
        </Button>
      )}
      dropHorizontalAlignment={(isLoggedInMobile || isTetheredMode) && !isSimpleLayout ? 'left' : 'right'}
      className={classNames(styles.dropDownList, {
        [styles.isNotLoggedIn]: (!loggedIn && !isTetheredMode) || isSimpleLayout,
        [styles.loggedIn]: loggedIn && !isSimpleLayout,
      })}
      data-stable-name="LocalizationSelectorLabel"
      isOpen={isDropDownOpened}
      dropWidth="unset"
      onOpenChange={handleOpenChange}
    >
      <Menu
        className={styles.menu}
        data-stable-name="LocalizationSelectorMenu"
        autoFocus={isDropDownOpened}
        selectedItem={selectedItem}
      >
        {items.map((item) => (
          <MenuItem
            className={(item.value === selectedItem?.value ? styles.menuItemSelected : styles.menuItem)}
            getItemIcon={() => (item.value === selectedItem?.value ? <Check /> : <Empty />)}
            data-stable-name={`LocalizationSelectorMenu-${item.value}-Item`}
            onSelect={() => handleItemClick(item)}
            key={item.value}
            value={item.value}
            data={item.label}
            aria-selected={item.value === selectedItem?.value}
          />
        ))}
      </Menu>
    </DropDownMenu>
  );
};
