import { PlatformKind } from '@app/queryTyping';

import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Returns the platform of the widget.
 */
export function getWidgetPlatform(widget: WidgetType): PlatformKind {
  // eslint-disable-next-line no-underscore-dangle
  switch (widget.__typename) {
    case 'Widget':
    case 'ImageWidget':
    case 'ThemeAwareImageWidget':
    case 'CustomAppWidget':
    case 'SsoWidget':
      return widget.platform;
    default:
      return PlatformKind.BOTH;
  }
}
