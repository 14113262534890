import React, {
  useCallback, useMemo, useState,
} from 'react';

import classNames from 'classnames';

import Times from '@ast/magma/components/icon/icons/Times';

import { Button } from '@ast/magma';

import { Widget } from '@app/queryTyping';

import { getAbsoluteURL } from '@app/common/utils/url';

import { PureWidget, WidgetRenderer, WidgetType } from '../types';

import styles from './htmlWidget.pcss';
import { closeWidget, isClosableWidget } from './utils';

/**
 * Html widget type guard.
 * @param widget orchard widget.
 * @returns true if widget is html widget.
 */
export function isHtmlWidget(widget: WidgetType): widget is PureWidget<Widget> {
  return widget.__typename === 'Widget' && widget.html !== undefined;
}

/**
 * Workaround solution to replace relative source paths for images to absolute paths.
 * @param html source widget html
 */
export const replaceRelativeMedia = (html: string): string => html.replace(
  /(\s(?:href|src)\s?=\s?["'])(\/media\/[^'"#]+)(["'])/gim,
  (match, prefix, relativeURL, suffix) => [prefix, getAbsoluteURL(relativeURL), suffix].join(''),
);

export const HtmlWidgetRenderer: WidgetRenderer<Widget> = ({ widget }) => {
  const [closed, setIsClosed] = useState(false);
  const isClosable = useMemo(() => isClosableWidget(widget), [widget]);

  const onClose = useCallback(() => {
    closeWidget(widget);
    setIsClosed(true);
  }, [widget]);

  if (closed) {
    return null;
  }

  return (
    <>
      <div
        className={styles.htmlWidget}
        dangerouslySetInnerHTML={{
          __html: replaceRelativeMedia(widget?.html!),
        }}
      />
      {isClosable && (
        <Button
          predefinedSize="small"
          className={classNames(styles.closeButton, 'widget-close-button')}
          data-stable-name="widgetCloseButton"
          look="icon"
          onClick={onClose}
        >
          <Times />
        </Button>
      )}
    </>
  );
};
