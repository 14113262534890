import React, { useMemo } from 'react';

import { FullscreenLayout } from '@app/common/layouts/FullscreenLayout/FullscreenLayout';

import { getNotFoundError } from '@app/core/widgets/pages';
import { isOrpeusWidget } from '@app/core/widgets/react/orpheusWidget';

import { ZoneName } from '../WidgetZone/constants';
import { useZoneWidgets } from '../WidgetZone/hooks/useZoneWidgets';

export const Page = React.memo(() => {
  const {
    loading,
    readyWidgets,
    zoneWidgets,
    isCompleted,
  } = useZoneWidgets(ZoneName.Middle);

  // Extract ready widget elements.
  const elements = useMemo(() => readyWidgets.map((widget) => widget.element), [readyWidgets]);

  // The page component must ensure if there will be no conditional widgets will appear before throwing
  // the page not found error. Otherwise, when a conditional widget will be ready, the page is already will be
  // locked with the error message.
  if (isCompleted && elements.length === 0) {
    throw getNotFoundError();
  }

  // A layout is needed if there is at least one Orpheus widget on the page.
  const needLayout = !zoneWidgets.find((widget) => isOrpeusWidget(widget.type));

  return needLayout && !loading ? (
    <FullscreenLayout removeHeaderSection>
      {elements}
    </FullscreenLayout>
  ) : <>{elements}</>;
});
