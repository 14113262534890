import { isCarouselCompatibleWidgetType } from '@app/common/components/Carousel/utils/isCarouselCompatibleWidgetType';

import { ZoneWidget } from '../types';

/**
 * Splits the given widgets into two groups: widgets that are compatible with the widget carousel and other widgets.
 */
export function splitCarouselWidgets<T extends ZoneWidget = ZoneWidget>(widgets: T[]) {
  const matching: T[] = [];
  const other: T[] = [];

  widgets.forEach((widget) => {
    const { type } = widget;

    if (!isCarouselCompatibleWidgetType(type)) {
      other.push(widget);
      return;
    }

    if (!type.useCarousel) {
      other.push(widget);
      return;
    }

    matching.push(widget);
  });

  return {
    matching,
    other,
  };
}
