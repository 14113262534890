import { useEffect, useState } from 'react';

import { ReCaptchaVersionCodes } from '@app/queryTyping';

import { isReCaptchaError } from '@app/core/components/ReCaptcha/reCaptchaApolloLink';
import { ReCaptchaGraphQLErrorExtension } from '@app/core/components/ReCaptcha/types';

export const useReCaptchaValidationException = () => {
  const [exception, setException] = useState<{
    message: string;
    version: ReCaptchaVersionCodes
  }>();

  useEffect(() => {
    const listener = (msg: MessageEvent) => {
      if (isReCaptchaError(msg.data)) {
        setException({
          message: msg.data.message,
          version: (msg.data.extensions as ReCaptchaGraphQLErrorExtension).versionCode,
        });
      }
    };

    window.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      listener(event);
    });
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return exception;
};
