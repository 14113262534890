import React from 'react';

import { ConnectedWizardStep, WizardStep } from '@app/common/configurable-wizards';
import {
  DeliveryAddressIdFieldLocator,
} from '@app/common/configurable-wizards/fields/DeliveryAddressId';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/wizard-step/WizardStepWithHeader';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginWizardButtons } from '../../LoginWizardButtons';

import styles from './index.pcss';

export const SelectOneTimePinAddress: React.FC<ConnectedWizardStep> = ({
  locators,
  ...restParams
}) => {
  const { t } = useUserLoginTranslation();
  const stepLocators = [DeliveryAddressIdFieldLocator, ...(locators || [])];
  const { name, allowBackRedirect } = useWizardStepStateContext();

  return (
    <WizardStepWithHeader
      title={name || ''}
      contentClassName={styles.content}
    >
      <WizardStep
        locators={stepLocators}
        {...restParams}
        buttons={({ back }) => (
          <LoginWizardButtons
            showBackButton={allowBackRedirect}
            showCancelButton={false}
            continueTitle={t(
              'select-one-time-pin-address.wizard.buttons.continue.title|Select One Time Pin Address step continue button title',
              'Continue',
            )}
            back={back}
            direction="column"
          />
        )}
      />
    </WizardStepWithHeader>
  );
};
