import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CacheManager } from '@app/common/utils/cacheManager';

import { client } from '@app/core/apolloClient';

/**
 * Message event payload for navigation.
 */
type NavigateInternalPayload = {
  route: string;
  resetCache?: boolean;
};

/**
 * Message event format.
 */
type NavigateInternalMessage = {
  version: '1.0';
  messageType: 'navigateInternal';
  payloadVersion: '1.0';
  payload: NavigateInternalPayload;
};

/**
 * Type guard to check if the message event is a navigateInternal message.
 * @param event message event
 * @returns true if the event is a valid navigateInternal message
 */
export function isNavigateInternalMessage(event: MessageEvent): event is MessageEvent<NavigateInternalMessage> {
  return (
    typeof event.data === 'object'
    && event.data !== null
    && event.data.version === '1.0'
    && event.data.messageType === 'navigateInternal'
    && event.data.payloadVersion === '1.0'
    && typeof event.data.payload === 'object'
    && typeof event.data.payload.route === 'string'
  );
}

/**
 * Hook to listen to postMessage events for internal navigation.
 * @implements https://cf.mfmnow.com/display/ARCH/Payload+navigateInternal
 */
export const useNavigateInternalV1 = () => {
  const history = useHistory();

  /**
   * Handle postMessage navigation event.
   * @param event message event
   */
  const handleEvent = (event: MessageEvent) => {
    if (isNavigateInternalMessage(event)) {
      const { route } = event.data.payload;
      history.push(route);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, []);
};
