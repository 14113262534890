import React, { useEffect, useState } from 'react';

import { Alert } from '@ast/magma/components/alert';

import { CountdownAlert } from '@app/common/components/CountdownAlert/CountdownAlert';
import {
  ConnectedWizardStep, isEmailField, WizardStep,
} from '@app/common/configurable-wizards';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/wizard-step/WizardStepWithHeader';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginWizardButtons } from '../../LoginWizardButtons';
import { EmailVerificationFieldLocator } from './fields/EmailVerificationField';
import { PrimaryEmailAbsentFieldLocator } from './fields/PrimaryEmailAbsentField.LabelFieldInfo';

import './styles.pcss';
import { emailChangedEventName } from './constants';
import { EmailChangedNotifier } from './EmailChangedNotifier';

export const EmailVerification: React.FC<ConnectedWizardStep> = ({ locators, onTerminationStep, ...restParams }) => {
  const { t } = useUserLoginTranslation();
  const { fields } = useWizardStepStateContext();
  const [continueButtonDisabled, setContinueButtonDisabled] = useState<boolean>(false);
  const sendTimeout = 120;
  const stepLocators = [
    EmailVerificationFieldLocator,
    PrimaryEmailAbsentFieldLocator,
    ...(locators || []),
  ];

  const hasEmailField = !!fields.find((field) => isEmailField(field));

  useEffect(() => {
    const emailChangedHandler = () => (setContinueButtonDisabled(false));

    EmailChangedNotifier.getInstance().addListener(emailChangedEventName, emailChangedHandler);

    return () => EmailChangedNotifier.getInstance().removeListener(emailChangedEventName, emailChangedHandler);
  }, []);

  const renderCountdownComponent = () => (
    <>
      <Alert
        level="success"
        title=""
        data-stable-name="EmailSentSuccessMessage"
      >
        {t('login.email-verification.success.message|Email verification success message',
          'Verification email sent successfully. Check your inbox for verification instructions.')}
      </Alert>
      <CountdownAlert
        countdownSeconds={sendTimeout}
        message={t('login.email-verification.countdown.message|Email verification countdown message',
          'You can request another email')}
        countdownAction={() => (setContinueButtonDisabled(false))}
      />
    </>
  );

  return (
    <WizardStepWithHeader
      title={hasEmailField
        ? t('email-verification.title.email-present|Email verification step title when the email is present or is editable', 'Please verify your email address')
        : t('email-verification.title.email-absent|Email verification step title when the email is absent and is not editable', 'No email address for your account')}
    >
      <WizardStep
        locators={stepLocators}
        {...restParams}
        buttons={({ back }) => (
          <LoginWizardButtons
            showBackButton={false}
            showContinueButton={hasEmailField}
            continueTitle={t(
              'email-verification.send-button|Email verification step send button title',
              'Send verification email',
            )}
            cancelTitle={t(
              'email-verification.cancel-button|Email verification step cancel button title',
              'Return to login',
            )}
            back={back}
            continueButtonDisabled={continueButtonDisabled}
          />
        )}
        onTerminationStep={() => (setContinueButtonDisabled(true))}
        afterFieldsContent={continueButtonDisabled ? renderCountdownComponent : undefined}
      />
    </WizardStepWithHeader>
  );
};
