import React from 'react';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';

export const LoginWizardStep: React.FC = ({ children }) => (
  <FlexContainer direction="column">
    {React.Children.map(children, (child) => {
      if (child) {
        return (
          <FlexContainer direction="column">
            {child}
          </FlexContainer>
        );
      }

      return child;
    })}
  </FlexContainer>
);
