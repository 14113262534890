/* eslint-disable i18next/no-literal-string */

import { CssVariable } from '@app/queryTyping';

import { ColorTheme } from '@app/common/types/ColorTheme';

import { client } from '@app/core/apolloClient';

import { GetBrandingVariablesDocument, GetBrandingVariablesQuery } from './queries/queryTyping/getBrandingVariables';

/**
 * Css variable mapping used in cases than BT API varible name differs from
 * css variable used in the web application
 */
const variablesMapping:Record<string, string> = {
};

/**
 * Define custom css variable string.
 * @param name css variable name
 * @param value css variable value
 * @returns css string
 */
const createCustomCSSVariable = (name:string, value:string):string => (`--${name}: ${value}`);

/**
 * Convert Branding tool API variables list into actual css variables
 * @param theme color theme value
 * @param variables Branding tool API variables list
 */
const initThemeVariables = (theme:ColorTheme, variables:CssVariable[]) => {
  // Create style element to host css variables from Branding Tool API
  const style = document.createElement('style');
  // Define color theme selector
  // NOTE: selector should be more specific than common light\dark theme variable selector in
  // order to be able to override default value specified in all files across the project.
  const dataTheme = `body[data-theme="${theme}"]`;

  // Create css variables list string
  const variablesStyle = variables.map((variable) => {
    // Get css variable name using custom mapper
    const cssVariableName = variablesMapping[variable.name] || variable.name;
    // Constrcut css varialbe string
    return createCustomCSSVariable(cssVariableName, variable.value);
  }).join('; ');

  style.innerHTML = `${dataTheme} { ${variablesStyle}; }`;
  // Insert css variables style on top of body to override header styles inserted from Magma
  document.body.insertBefore(style, document.body.firstChild);
};

/**
 * Initialize branding tool basic variables (in global css scope)
 * @returns promise then init complete
 */
export const initializeBranding = async () => (
  client.query<GetBrandingVariablesQuery>({
    fetchPolicy: 'network-only',
    query: GetBrandingVariablesDocument,
  })
    .then((result) => {
      if (result?.data?.branding) {
        initThemeVariables(ColorTheme.light, result.data.branding.lightThemeVariables);
        initThemeVariables(ColorTheme.dark, result.data.branding.darkThemeVariables);
      }
    }));
