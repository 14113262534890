/* eslint-disable no-underscore-dangle */
import { PasskeyOnboardingPresentingProps } from '@app/features/passkey-onboarding/types';

import { isNullOrUndefined } from '@app/common/utils';

import { LocalStorageDocument } from '@app/core/storage/browser/LocalStorageDocument';
import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

import { DEFAULT_DEFERRAL_PERIOD_IN_DAYS } from './const';

const CURRENT_VERSION = 1;
const NODE_KEY = 'passkey.onboarding';
export type PasskeyOnboardingPresentingUserProps = Pick<PasskeyOnboardingPresentingProps, 'lastSkip' | 'skipCount'>;

export type IPasskeyOnboardingLocalStorage = {
  usersData: Record<string, PasskeyOnboardingPresentingUserProps>;
  deferralPeriodInDays: number[];
};

export class PasskeyOnboardingLocalStorage extends LocalStorageDocument<IPasskeyOnboardingLocalStorage> {
  /**
   * Singleton methods
   */
  private static inner?: PasskeyOnboardingLocalStorage;

  private _usersData!: Map<string, Pick<PasskeyOnboardingPresentingProps, 'lastSkip' | 'skipCount'>>;

  private _deferralPeriodInDays: number[] = DEFAULT_DEFERRAL_PERIOD_IN_DAYS;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node: string, version: number, migrations: Migration[] = []) {
    super(node, version, migrations);

    if (!this._usersData) {
      this._usersData = new Map();
    }
  }

  public static get instance(): PasskeyOnboardingLocalStorage {
    if (!this.inner) {
      this.inner = new PasskeyOnboardingLocalStorage(NODE_KEY, CURRENT_VERSION);
    }

    return this.inner;
  }

  public get deferralPeriodInDays(): number[] {
    return this._deferralPeriodInDays;
  }

  public set deferralPeriodInDays(value: number[]) {
    this._deferralPeriodInDays = value;
    this.save();
  }

  public getUserData(userId: string): PasskeyOnboardingPresentingUserProps | undefined {
    const usersData = this._usersData.get(userId);
    if (isNullOrUndefined(usersData)) {
      return undefined;
    }

    return usersData;
  }

  public setUserData(userId: string, { lastSkip, skipCount }: PasskeyOnboardingPresentingUserProps): void {
    this._usersData.set(userId, {
      lastSkip,
      skipCount,
    });
    this.save();
  }

  /**
   * Save public data properties into serializable data structure.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize() : IPasskeyOnboardingLocalStorage {
    return {
      usersData: Object.fromEntries(this._usersData.entries()),
      deferralPeriodInDays: this._deferralPeriodInDays,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized: IPasskeyOnboardingLocalStorage) {
    this._usersData = new Map(Object.entries(deserialized.usersData ?? {}));
    this._deferralPeriodInDays = deserialized.deferralPeriodInDays;
  }
}
