import React, {
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { DeepTargetWidget } from '@app/queryTyping';

import { useDebouncedFunction } from '@app/common/utils/hooks/useDebouncedFunction';
import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';

import { PureWidget, WidgetRenderer, WidgetType } from '../types';

import { IFRAME_VIEWPORT_OFFSET } from './constants';
import styles from './deepTarget.pcss';
import { StoryPopup } from './StoryPopup';

/**
 * Deep Target widget type guard.
 * @param widget.
 * @returns true if widget is Deep Target widget.
 */
export function isDeepTargetWidget(
  widget: WidgetType,
): widget is PureWidget<DeepTargetWidget> {
  return widget.__typename === 'DeepTargetWidget' && widget.url !== undefined;
}

export enum DeepTargetEventMessage {
  DeepTarget = 'DeepTarget',
  '3DStory' = '3DStory',
}

export const useWrapperWidth = () => {
  const isMobile = useIsMobile();
  const style = getComputedStyle(document.body);
  const mainContainerWidth = parseFloat(style.getPropertyValue('--container-max-width'))
    * parseFloat(style.fontSize);
  const baseWidth = mainContainerWidth > document.body.clientWidth
    ? document.body.clientWidth
    : mainContainerWidth;
  const paddingVariable = isMobile ? '--space-sm' : '--space';
  const pagePadding = parseFloat(style.getPropertyValue(paddingVariable)) * parseFloat(style.fontSize);

  return baseWidth - pagePadding * 2;
};

export const DeepTargetRenderer: WidgetRenderer<DeepTargetWidget> = ({
  widget: {
    url: baseSrc,
    height,
    width,
    mobileWidth,
    mobileHeight,
    is3DStoryWidget,
  },
}) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const isMobile = useIsMobile();
  const [opened, setOpened] = useState(false);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const onResize = useDebouncedFunction(() => {
    forceUpdate();
  }, 1000);

  const onOpenPopup = (event: MessageEvent) => {
    const msg = event.data;
    if (
      msg[0] === DeepTargetEventMessage.DeepTarget
      && msg[1] === DeepTargetEventMessage['3DStory']
    ) {
      setOpened(true);
    }
  };

  const currentUserInfo = useCurrentUserInfoContext();

  const wrapperWidth = useWrapperWidth();
  const renderHeight = parseFloat(
    isMobile && mobileHeight ? mobileHeight : height!,
  ).toString();
  const renderWidth = parseFloat(
    isMobile && mobileWidth ? mobileWidth : width!,
  ).toString();
  const deepTargetId = currentUserInfo?.deepTargetId || '';

  const isVerticalBanner = +renderHeight > +renderWidth;

  const iframeSize = useMemo(() => {
    if (isVerticalBanner) {
      // for vertical banner exact sizes is used
      return {
        width: String(
          is3DStoryWidget ? +renderWidth + IFRAME_VIEWPORT_OFFSET : +renderWidth,
        ),
        height: String(
          is3DStoryWidget
            ? +renderHeight + IFRAME_VIEWPORT_OFFSET
            : +renderHeight,
        ),
      };
    }

    return {
      width: String(Math.round(wrapperWidth)),
      height: String(Math.round(wrapperWidth * (+renderHeight / +renderWidth))),
    };
  }, [renderHeight, renderWidth, wrapperWidth]);

  const src = useMemo(() => {
    const replacements = {
      'DEEP-TARGET-ID': deepTargetId,
      // only desktop sizes is used for request to deeptarget
      HEIGHT: parseFloat(height!).toString(),
      WIDTH: parseFloat(width!).toString(),
    };

    // eslint-disable-next-line i18next/no-literal-string
    const regexp = new RegExp(Object.keys(replacements).join('|'), 'g');

    const url = new URL(
      baseSrc!.replace(
        regexp,
        (matched) => replacements[matched as keyof typeof replacements],
      ),
    );
    url.searchParams.append('PT', 'JS');
    // eslint-disable-next-line i18next/no-literal-string
    url.searchParams.append('imageclass', 'DTImg-responsive');
    // eslint-disable-next-line i18next/no-literal-string
    url.searchParams.append('containerid', 'adContainer');

    if (is3DStoryWidget) {
      // eslint-disable-next-line i18next/no-literal-string
      url.searchParams.append('adType', '3dstory');
    }

    return url.toString();
  }, [currentUserInfo, baseSrc, width, height]);

  useEffect(
    () => {
      if (!isVerticalBanner) {
        forceUpdate(); // for size calcution after mount
      }

      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
        ref.current?.contentWindow?.removeEventListener('message', onOpenPopup);
      };
    },
    [/* have to be empty */],
  );

  useEffect(() => {
    const iframeDocument = ref.current?.contentWindow?.document;
    iframeDocument?.write(`<div id="adContainer">
      <style>body { margin: 0; }</style>
      <script type="text/javascript" src="${src}"></script>
    </div>`);
    iframeDocument?.close();
  }, [src]);

  return (
    <>
      <StoryPopup
        baseSrc={baseSrc!}
        deepTargetId={deepTargetId}
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      />
      <iframe
        ref={ref}
        scrolling="no"
        style={{
          border: 'none',
          maxWidth: '100%',
          width: `${iframeSize.width}px`,
          height: `${iframeSize.height}px`,
        }}
        title="deeptarget"
        className={!is3DStoryWidget ? styles.iframe : ''}
        onLoad={() => ref.current?.contentWindow?.addEventListener('message', onOpenPopup)}
      />
    </>
  );
};
