import { useMemo } from 'react';

import { useAppWidgetsContext } from '@app/core/contexts/appWidgets/AppWidgetsContext';
import { useGetApplicationWidgetsQuery } from '@app/core/widgets/queries/queryTyping/getApplicationWidgets';
import { useGetDynamicWidgetQuery } from '@app/core/widgets/queries/queryTyping/getDynamicWidget';

import { ZoneName } from '../constants';

import { usePagePath } from '../../Application/usePagePath';
import { getWidgetPlatform } from '../utils/getWidgetPlatform';
import { isHiddenWidget } from '../utils/isHiddenWidget';
import { mergeWidgets } from '../utils/mergeWidgets';

import { useCurrentPlatform } from './useCurrentPlatform';

/**
 * Resolves widgets for a specific zone, including global and page widgets.
 */
export const useZoneWidgetData = (zoneName: ZoneName) => {
  const currentPlatform = useCurrentPlatform();

  const { loading: loadingGlobalWidgets } = useGetApplicationWidgetsQuery();

  const appWidgetsContext = useAppWidgetsContext();

  const { pageId } = usePagePath();

  const { data: pageWidgetsData, loading } = useGetDynamicWidgetQuery({
    variables: { id: pageId! },
    skip: !pageId,
  });

  // Merge the app-wide widgets with the page-specific widgets.
  const widgets = useMemo(
    () => mergeWidgets((appWidgetsContext?.widgets)!, (pageWidgetsData?.pages)!)?.[zoneName.toLowerCase()] || [],
    [appWidgetsContext, pageWidgetsData, zoneName],
  );

  // Filter out the widgets that are hidden for the current platform.
  const data = useMemo(
    () => widgets.filter((widget) => !isHiddenWidget(getWidgetPlatform(widget), currentPlatform)),
    [widgets, currentPlatform],
  );

  return { data, loading: loading || loadingGlobalWidgets };
};
