import * as React from 'react';

import { createGenericContext } from '../../contexts/createGenericContext';

import { WizardStepStateDispatchAction } from './stepState';

const [
  useWizardStepStateDispatchContext,
  WizardStepStateDispatchContextProvider,
] = createGenericContext<React.Dispatch<WizardStepStateDispatchAction>>();

interface WizardStepStateDispatchProviderProps {
  value: React.Dispatch<WizardStepStateDispatchAction>
}

/**
 * Represents the wizard state dispatcher that can be used in nested components to update wizard-step state
 */
const WizardStepStateDispatchProvider: React.FC<WizardStepStateDispatchProviderProps> = ({ children, value }) => (
  <WizardStepStateDispatchContextProvider value={value}>
    {children}
  </WizardStepStateDispatchContextProvider>
);

export { useWizardStepStateDispatchContext, WizardStepStateDispatchProvider };
