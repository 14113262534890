import { WidgetType } from '@app/core/widgets/react/types';

import { ZoneName } from '../constants';
import { PendingZoneWidget, ReadyZoneWidget } from '../types';

import { isConditionalWidget } from './isConditionalWidget';
import { renderConditionalWidget } from './renderConditionalWidget';

/**
 * Resolves a conditional zone widget.
 *
 * @param id Widget ID.
 * @param type Widget type.
 * @param zoneName Zone name the widget rendered in.
 *
 * @returns Pending zone widget descriptor and an async widget resolving function.
 */
export function resolveConditionalZoneWidget(id: string, type: WidgetType, zoneName: ZoneName) {
  const isConditional = isConditionalWidget(type);

  const pendingWidget: PendingZoneWidget = {
    id,
    state: 'pending',
    type,
    isConditional,
  };

  const resolveWidget = async () => {
    const element = await renderConditionalWidget(id, type, zoneName);

    const readyWidget: ReadyZoneWidget = {
      id,
      state: 'ready',
      type,
      element,
      isConditional,
    };

    return readyWidget;
  };

  return [pendingWidget, resolveWidget] as const;
}
