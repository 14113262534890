import React, { useMemo } from 'react';

import Biometric from '@ast/magma/components/icon/icons/Biometric';
import Lock from '@ast/magma/components/icon/icons/Lock';

import { SegmentedButtons } from '@ast/magma/components/segmentedbuttons';

import { isFunction } from '@app/common/utils';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginMode } from './types';

export interface LoginModeSwitcherProps {
  readonly defaultMode: LoginMode;
  readonly onLoginModeChange?: (mode: LoginMode) => void;
}

enum LoginSteps {
  PASSWORD = 0,
  BIOMETRIC = 1,
}

export const indexesToModes: Map<number, keyof typeof LoginMode> = new Map([
  [0, LoginMode.password],
  [1, LoginMode.biometric],
]);

export const modesToIndexes: Map<keyof typeof LoginMode, number> = new Map([
  [LoginMode.password, 0],
  [LoginMode.biometric, 1],
]);

export const LoginModeSwitcher: React.FC<LoginModeSwitcherProps> = ({
  defaultMode,
  onLoginModeChange,
}) => {
  const { t } = useUserLoginTranslation();

  const buttons = useMemo(() => [{
    title: t(
      'login.steps.username-password.login-mode-segment-control.password-label|Represent the option for the classic login mode with username-password',
      'Password',
    ),
    id: LoginSteps.PASSWORD.toString(),
    icon: <Lock />,
  },
  {
    title: t(
      'login.steps.username-password.login-mode-segment-control.biometric-label|Represent the option for the biometric login mode',
      'Biometric',
    ),
    id: LoginSteps.BIOMETRIC.toString(),
    icon: <Biometric />,
  }], []);

  return (
    <SegmentedButtons
      selectedId={modesToIndexes.get(defaultMode)?.toString() || ''}
      buttonsList={buttons}
      isEqualSize
      wrap
      onChange={(idx) => {
        if (idx !== null && isFunction(onLoginModeChange)) {
          onLoginModeChange(indexesToModes.get(Number(idx)) as LoginMode);
        }
      }}
    />
  );
};
