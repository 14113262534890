import React from 'react';
import { Trans } from 'react-i18next';

import { TextLink } from '@ast/magma/components/textlink';

import { FooterProps } from '../FooterProps';

/**
 * Static error dialog footer show support sense without any actual link to support page.
 * Used there we don't have access to FI settings.
 */
export const StaticErrorDialogFooter = ({ reloadHref, isInternalError }: FooterProps) => {
  let label = (
    <Trans i18nKey="error-dialog.static-footer.support|Generic error dialog common footer">
      Please
      {' '}
      <TextLink
        href={reloadHref ?? window.location.href}
        target="_top"
        rel="noopener noreferrer nofollow"
      >
        try again
      </TextLink>
      {' '}
      later, or contact us for help.
    </Trans>
  );

  if (isInternalError) {
    label = (
      <Trans i18nKey="error-dialog.static-footer.support-with-take-screenshot-label|Generic error dialog common footer with take screenshot label">
        Please
        {' '}
        <TextLink
          href={reloadHref ?? window.location.href}
          target="_top"
          rel="noopener noreferrer nofollow"
        >
          try again
        </TextLink>
        {' '}
        later, or take a screenshot and contact us for help.
      </Trans>
    );
  }

  return (
    <span>{ label }</span>
  );
};
