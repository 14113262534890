import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import { Card } from '@ast/magma/components/card';
import { Divider } from '@ast/magma/components/divider';
import { Loader } from '@ast/magma/components/loader';
import { TextLink } from '@ast/magma/components/textlink';

import { isNotAuthorizedError } from '@app/common/errors';
import { useGetMenuQuery } from '@app/common/types/queries/queryTyping/getNavigationMenu';
import { MenuItem } from '@app/common/types/useMainMenuQuery';
import { createIconProvider } from '@app/common/utils/iconsProvider';

import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { MenuItemEligibility } from '@app/core/components/MenuNavigation/MenuItemEligibility';
import { WidgetZone } from '@app/core/components/WidgetZone/WidgetZone';
import { EligibilityMenuItemsContext } from '@app/core/contexts/eligibilityMenuContext/EligibilityMenuItemsContext';
import { t } from '@app/core/i18n';

import { ZoneName } from '../WidgetZone/constants';
import { QuickLaunch } from './quicklaunch/quicklaunch';

import styles from './Footer.pcss';

/**
 * Icon provider for footer menu.
 */
const getFooterMenuIcon = createIconProvider();

/**
 * Footer component
 */
export const Footer: React.FC = () => {
  const menuId = 'dashboard-footer-menu';

  const { data: footerMenuData, loading, error } = useGetMenuQuery({
    variables: {
      ids: menuId,
    },
  });
  const footerRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpoint().device === 'mobile';
  // eslint-disable-next-line i18next/no-literal-string
  const isLoggedIn = useIsLoggedIn();
  const [empty, setEmpty] = useState<boolean>(true);
  const quickLaunchElementRef = useRef<HTMLDivElement | null>(null);

  /**
   * Update empty state for footer container.
   */
  useEffect(() => {
    if (footerRef.current) {
      const footerZone = footerRef.current.querySelector("[widget-zone='Footer']");
      const footerMenuContainer = document.getElementById('footerMenuContainer');
      setEmpty((!footerZone || footerZone.childElementCount === 0) && !footerMenuContainer);
    }
  });

  /**
   * Update bottom margin for footer in mobile view to make
   * footer visible beside quick launch.
   */
  const updateQuickLaunchGap = () => {
    if (footerRef.current) {
      const targetForMeasuring = isMobile ? quickLaunchElementRef.current?.querySelector('nav') : undefined;

      footerRef.current.style.marginBottom = targetForMeasuring
        ? getComputedStyle(targetForMeasuring).height
        : '0';
    }
  };

  /**
   * Update footer with quick launch gap when quick launch gap menu appears\disappears.
   */
  const handleQuickLaunchRef = useCallback((quickLaunchElement:HTMLDivElement) => {
    quickLaunchElementRef.current = quickLaunchElement;
    updateQuickLaunchGap();
  }, []);

  /**
   * Update footer with quick launch gap when mobile\login state changes.
   */
  useEffect(() => {
    updateQuickLaunchGap();
  }, [isMobile, isLoggedIn]);

  // Skip error if footer menu is only to be shown for authorized users.
  // In all other cases just throw an error.
  if (error && (!isNotAuthorizedError(error) && !isLoggedIn)) {
    throw error;
  }

  return (
    <>
      <footer
        className={styles.footer}
        ref={footerRef}
        data-stable-name="FooterMenu"
      >
        <Card
          className={classNames(styles.container, isLoggedIn && styles.quickLaunchGap, empty && styles.hidden)}
        >

          { loading && <Loader size="md" />}

          <EligibilityMenuItemsContext>
            { footerMenuData?.menu && footerMenuData?.menu[0] && footerMenuData.menu[0].menuItems && footerMenuData.menu[0].menuItems.length > 0 && (
            <nav
              id="footerMenuContainer"
              className={styles.menuLine}
              data-stable-name="FooterMenuLine"
              aria-label={t(
                'page-layout.footer-links.navigation-name|Accessible text for navigation links in footer',
                'Footer menu',
              )}
            >
              {
                footerMenuData.menu[0].menuItems.map((menuItem, index, source) => menuItem && (
                  <MenuItemEligibility menuItem={menuItem as MenuItem} key={menuItem.id}>
                    <span
                      className={styles.linkItem}
                      key={menuItem.linkName}
                    >
                      <TextLink
                        className={styles.link}
                        href={menuItem.url ?? ''}
                        target="_blank"
                        data-stable-name={`FooterMenuItem-${menuItem?.id}`}
                        rel="noopener noreferrer nofollow"
                      >
                        { menuItem.icon && getFooterMenuIcon(menuItem.icon, styles.icon) }
                        { menuItem.linkName && <span>{menuItem.linkName}</span> }
                      </TextLink>
                      { (index < source.length - 1) && (
                      <Divider className={styles.divider} orientation="vertical" />
                      ) }
                    </span>
                  </MenuItemEligibility>
                ))
              }
            </nav>
            )}
          </EligibilityMenuItemsContext>
          <WidgetZone
            zoneName={ZoneName.Footer}
            className={styles.logo}
          />
        </Card>
      </footer>
      {(isMobile && isLoggedIn && !isEmbeddedMode()) && (
        <EligibilityMenuItemsContext>
          <QuickLaunch ref={handleQuickLaunchRef} />
        </EligibilityMenuItemsContext>
      )}
    </>
  );
};
