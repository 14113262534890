/* eslint-disable no-underscore-dangle */
import { isClosableWidget } from '@app/core/widgets/react/htmlWidget/utils';
import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Checks if the widget is a conditional one.
 */
export function isConditionalWidget(widget: WidgetType) {
  switch (widget.__typename) {
    case 'SsoWidget':
      // The widget is conditional if it is set to hide if ineligible.
      return Boolean(widget.hideIfIneligible);

    case 'Widget':
      return isClosableWidget(widget);

    default:
      return false;
  }
}
