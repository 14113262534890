import { WidgetType } from '@app/core/widgets/react/types';

import { ZoneName } from '../constants';
import { ReadyZoneWidget } from '../types';

import { isConditionalWidget } from './isConditionalWidget';
import { renderWidget } from './renderWidget';

/**
 * Resolves a persistent zone widget.
 *
 * @param id Widget ID.
 * @param type Widget type.
 * @param zoneName Zone name the widget rendered in.
 *
 * @returns Ready zone widget descriptor.
 */
export function resolvePersistentZoneWidget(id: string, type: WidgetType, zoneName: ZoneName) {
  const readyWidget: ReadyZoneWidget = {
    id,
    state: 'ready',
    element: renderWidget(id, type, zoneName),
    type,
    isConditional: isConditionalWidget(type),
  };

  return readyWidget;
}
