import { ReadyZoneWidget } from '../types';

/**
 * Picks non-null elements from the ready zone widgets.
 */
export function pickVisibleElements(widgets: ReadyZoneWidget[]): JSX.Element[] {
  return widgets
    .map((widget) => widget.element)
    // TypeScript 4.x is not able to properly infer the final type, so the type cast is required.
    .filter((element) => element !== null) as JSX.Element[];
}
