import React, { PropsWithChildren } from 'react';

/**
 * {@link WidgetWrapper} properties.
 */
export type WidgetWrapperProps = PropsWithChildren<{}>;

/**
 * A common wrapper for widgets.
 */
export const WidgetWrapper: React.FC<WidgetWrapperProps> = ({ children }) => (
  <div className="widget-container">{children}</div>
);
