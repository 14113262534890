import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Alert } from '@ast/magma/components/alert';

import { WizardStepOverlaySpinner } from '@app/common/components/configurable-wizards/WizardStepOverlaySpinner';
import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';
import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { ReCaptchaPrivacyAndTermsLinksContainer } from '@app/common/components/ReCaptcha/ReCaptchaPrivacyAndTermsLinks';
import {
  ConnectedWizardStep,
  FieldDefaultValue,
  setFieldUiProps,
  setWizardStepAlertMessage,
  setWizardStepGenericErrors,
  setWizardStepLoading,
  setWizardStepState,
  WizardStep,
  WizardStepButtonsRenderer,
} from '@app/common/configurable-wizards';
import {
  PasswordFieldWithLockIconComponent,
  PasswordFieldWithLockIconLocator,
} from '@app/common/configurable-wizards/fields/Password/PasswordWithLockIcon';
import { UsernameFieldComponent, usernameFieldId, UsernameLocator } from '@app/common/configurable-wizards/fields/Username';
import { useWizardStepStateDispatchContext } from '@app/common/configurable-wizards/state/WizardStateDispatchContext';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';
import { isNullOrUndefined } from '@app/common/utils';

import { isTetheredLogin } from '@app/core/components/TetheredLoginApplication';
import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';
import { PageURL } from '@app/core/widgets/pages';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';
import { LoginLocalSettings } from '@app/widgets/user-login/LoginLocalSettings';
import { UserLoginSessionStorage } from '@app/widgets/user-login/LoginWizard/UserLoginSessionStorage';
import { LoginWizardStepRouterParams } from '@app/widgets/user-login/types';

import { LoginWizardStep } from '../LoginWizardStep';

import { AuthContextDataKeys, LOGIN_MODE_KEY } from '../../constants';
import { useTetheredLoginLazyQuery } from '../../queries/queryTyping/tethered-login';
import { useGetLoginSettingsQuery } from './queries/queryTyping/get-login-settings';

import { Buttons } from './Buttons';
import { Links } from './Links';
import { LoginModeSwitcher } from './LoginModeSwitcher';
import { LoginMode } from './types';
import { useFields } from './useFields';
import { usePasskeyLogin } from './usePasskeyLogin';
import { useReCaptcha } from './useReCaptcha';
import styles from './UsernamePassword.pcss';
import { checkPasskeyAvaialble } from './utils';

export const UsernamePassword: React.FC<ConnectedWizardStep> = ({ locators, ...restParams }) => {
  const { t } = useUserLoginTranslation();
  const stepState = useWizardStepStateContext();
  const dispatch = useWizardStepStateDispatchContext();
  const stepLocators = [
    UsernameLocator,
    PasswordFieldWithLockIconLocator,
    ...(locators || []),
  ];

  const tetheredLogin = useRouteMatch(`${PageURL.USER_LOGIN}/tethered/:token`);
  const isTetheredMode = isTetheredLogin(window.location.pathname);

  const token = (tetheredLogin?.params as LoginWizardStepRouterParams)?.token || '';

  const history = useHistory();
  const [defaultValues, setDefaultValues] = useState<Map<string, FieldDefaultValue>>();

  const isAutoLogoutProceeded = useMemo<boolean>(() => {
    const userLoginSessionStorage = UserLoginSessionStorage.getInstance();
    const autoLogoutRedirection = userLoginSessionStorage.getAutoLogoutRedirection();

    // clear auto-logout data after using
    userLoginSessionStorage.setAutoLogoutRedirection(null);

    return !isNullOrUndefined(autoLogoutRedirection);
  }, []);

  const [getTetheredLogin, {
    data: tetheredLoginQueryData,
  }] = useTetheredLoginLazyQuery();

  useEffect(() => {
    if (token) {
      const variables = {
        tetheredLoginParams: {
          token,
        },
      };

      getTetheredLogin({ variables });

      history.replace(PageURL.USER_LOGIN);
    }
  }, [token]);

  // prefill username if it was passed from tethered login
  useEffect(() => {
    if (tetheredLoginQueryData?.tetheredLogin?.username) {
      const defaultFieldValues = new Map<string, FieldDefaultValue>([
        [
          usernameFieldId, {
            defaultValue: tetheredLoginQueryData.tetheredLogin.username,
            needTriggerChange: true,
          },
        ],
      ]);

      setDefaultValues(defaultFieldValues);
    }
  }, [tetheredLoginQueryData]);

  // used for switching between login modes
  const [loginMode, setLoginMode] = useState<LoginMode>(LoginLocalSettings.instance.prefferableLoginMode);

  // flag to determine if the user is attempting a passkey login
  const [attemptLoginWithPasskey, setAttemptLoginWithPasskey] = useState(false);
  // save the user's preferred login mode to local storage
  useEffect(() => {
    LoginLocalSettings.instance.prefferableLoginMode = loginMode;
  }, [loginMode]);

  // get current login settings
  const { loading, data: loginSettingsData } = useGetLoginSettingsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const quickLoginEnabled = loginSettingsData?.loginSettings.quickLoginSettings.isEnabled;
  const passwordVisibilityControlEnabled = loginSettingsData?.loginSettings.passwordVisibilityControlSettings.isEnabled;
  const isPasskeyEnabled = loginSettingsData?.loginSettings.passkeyLoginSettings.isEnabled;

  // save the passkey login availability to local storage
  useEffect(() => {
    LoginLocalSettings.instance.isPasskeyEnabled = Boolean(isPasskeyEnabled) && checkPasskeyAvaialble();
  }, [isPasskeyEnabled]);

  // if the biometric login is disabled, switch user-preferable mode to the password login
  useEffect(() => {
    if (!quickLoginEnabled) {
      setLoginMode(LoginMode.password);
    }
  }, [quickLoginEnabled]);

  // set password field UI props to show/hide password visibility control
  useEffect(() => {
    dispatch(
      setFieldUiProps([
        PasswordFieldWithLockIconComponent, { showPasswordVisibilityControl: passwordVisibilityControlEnabled },
      ]),
    );
  }, [passwordVisibilityControlEnabled, dispatch]);

  // set the focus on the username field by default
  useEffect(() => {
    dispatch(
      setFieldUiProps([
        UsernameFieldComponent, { autoFocus: true },
      ]),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setFieldUiProps([
        UsernameFieldComponent, { autoFocus: true, passkeyAutoFill: Boolean(isPasskeyEnabled) },
      ]),
    );
  }, [isPasskeyEnabled]);

  // use an actualized set of fields based on the current login mode
  const fields = useFields({
    loginMode,
    initialFields: stepState.fields,
  });

  // update step state with actualized fields
  useEffect(() => {
    dispatch(setWizardStepState({
      ...stepState,
      fields,
    }));
  }, [fields, dispatch]);

  // clear errors on switching login-mode
  useEffect(() => {
    dispatch(setWizardStepGenericErrors([]));
  }, [loginMode, dispatch]);

  const { startPasskeyAuth, startPasskeyConditionalAuth } = usePasskeyLogin({
    onLoadingStateChange: (loadingState) => dispatch(setWizardStepLoading(loadingState)),
    onError: () => dispatch(setWizardStepAlertMessage({
      content: t('login.passkey.error.message|Login passkey error message', 'An error occurred while trying to authenticate with a passkey'),
      type: 'error',
    })),
  });

  const submitFormRef = useRef<(contextData?: Record<string, unknown>) => void>();

  const buttons: WizardStepButtonsRenderer = useCallback(({ form, submit }) => {
    // keep a reference to the submit fn for auto submiting the form after recaptcha is passed
    submitFormRef.current = submit;

    return (
      <Buttons
        onSubmitPasskey={async (isAuthConditional?: boolean) => {
          setAttemptLoginWithPasskey(true);
          form.setValue(LOGIN_MODE_KEY, LoginMode.passkey);
          const authenticationCallback = isAuthConditional ? startPasskeyConditionalAuth : startPasskeyAuth;
          try {
            await authenticationCallback();
          } catch (error: any) {
            if (error.name === 'AbortError') {
            // Authentication aborted. Do nothing;
              return;
            }
            dispatch(setWizardStepLoading(false));

            return;
          }

          submit({
            [AuthContextDataKeys.passkeyAuth]: true,
          });
        }}
        setLoginMode={() => {
          setAttemptLoginWithPasskey(false);
          setLoginMode(LoginLocalSettings.instance.prefferableLoginMode);
          form.setValue(LOGIN_MODE_KEY, LoginLocalSettings.instance.prefferableLoginMode === LoginMode.biometric ? 'Quick' : LoginMode.password);
        }}
        isPasskeyEnabled={loginSettingsData?.loginSettings.passkeyLoginSettings.isEnabled && checkPasskeyAvaialble()}
      />
    );
  }, [
    restParams.onSubmit,
    loginSettingsData?.loginSettings.passkeyLoginSettings.isEnabled,
  ]);

  const { colorTheme } = useColorThemeContext();

  const recaptcha = useReCaptcha({
    onRecaptchaV2ChallengePassed: () => {
      submitFormRef.current?.();
    },
    colorTheme,
  });

  return (
    <FlexContainer
      className={styles.flexContainer}
      direction="column"
    >
      {/* Spinner */}
      <WizardStepOverlaySpinner loading={loading} />

      {/* Login mode control */}
      {quickLoginEnabled && (
        <LoginModeSwitcher
          defaultMode={loginMode}
          onLoginModeChange={setLoginMode}
        />
      )}

      {/* Wizard step content */}
      {!loading && (
        <WizardStep
          {...restParams}
          customSubmit={attemptLoginWithPasskey}
          isCustomSubmit={({ contextData }) => (
            Boolean(contextData?.[AuthContextDataKeys.passkeyAuth])
          )}
          buttons={buttons}
          locators={stepLocators}
          defaultFieldValues={defaultValues}
          render={(props) => (
            <LoginWizardStep>
              {props.alert}
              {isAutoLogoutProceeded && !props.formState.isSubmitted && (
                <Alert level="info">
                  {t(
                    'login.steps.username-password.auto-logout-redirection-text|Represents a notification if the user was automatically logout',
                    'You have been automatically logged out due to inactivity. Please log in again.',
                  )}
                </Alert>
              )}
              <>{props.fields}</>
              <>{recaptcha.visibleRecaptchaRender}</>
              {props.buttons}
            </LoginWizardStep>
          )}
          onSubmit={(params) => {
            recaptcha.onSubmit(() => {
              restParams.onSubmit(params);
            });
          }}
        />
      )}

      {/* Links to enroll/retrieval */}
      <Links />

      {/* Language selector for tethered login */}
      {isTetheredMode && <div><LocalizationSelectorWrapper /></div>}

      <ReCaptchaPrivacyAndTermsLinksContainer />
    </FlexContainer>
  );
};
