/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetDynamicWidgetQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetDynamicWidgetQuery = (
  { __typename?: 'Query' }
  & { pages: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'Page' }
    & { widgets: Types.Maybe<(
      { __typename?: 'ContentItem' }
      & { middle: Array<Types.Maybe<(
        { __typename?: 'Widget' }
        & Pick<Types.Widget, 'html' | 'platform'>
      ) | (
        { __typename?: 'ImageWidget' }
        & Pick<Types.ImageWidget, 'path' | 'platform'>
      ) | (
        { __typename?: 'ThemeAwareImageWidget' }
        & Pick<Types.ThemeAwareImageWidget, 'lightThemePath' | 'darkThemePath' | 'platform'>
      ) | (
        { __typename?: 'PlacementWidget' }
        & Pick<Types.PlacementWidget, 'placementType'>
      ) | { __typename?: 'Page' } | { __typename?: 'AdServiceWidget' } | (
        { __typename?: 'CustomAppWidget' }
        & Pick<Types.CustomAppWidget, 'url' | 'width' | 'height' | 'frameAttrs' | 'platform' | 'useCarousel'>
      ) | (
        { __typename?: 'DeepTargetWidget' }
        & Pick<Types.DeepTargetWidget, 'url' | 'width' | 'height' | 'mobileWidth' | 'mobileHeight' | 'is3DStoryWidget'>
      ) | { __typename?: 'Menu' } | (
        { __typename?: 'SsoWidget' }
        & Pick<Types.SsoWidget, 'providerId' | 'width' | 'height' | 'hideIfIneligible' | 'platform' | 'useCarousel'>
      ) | { __typename?: 'ContentItem' } | { __typename?: 'LinkMenuItem' }>>, header: Array<Types.Maybe<(
        { __typename?: 'Widget' }
        & Pick<Types.Widget, 'html' | 'platform'>
      ) | (
        { __typename?: 'ImageWidget' }
        & Pick<Types.ImageWidget, 'path' | 'platform'>
      ) | (
        { __typename?: 'ThemeAwareImageWidget' }
        & Pick<Types.ThemeAwareImageWidget, 'lightThemePath' | 'darkThemePath' | 'platform'>
      ) | { __typename?: 'PlacementWidget' } | { __typename?: 'Page' } | { __typename?: 'AdServiceWidget' } | (
        { __typename?: 'CustomAppWidget' }
        & Pick<Types.CustomAppWidget, 'url' | 'width' | 'height' | 'frameAttrs' | 'platform' | 'useCarousel'>
      ) | (
        { __typename?: 'DeepTargetWidget' }
        & Pick<Types.DeepTargetWidget, 'url' | 'width' | 'height' | 'mobileWidth' | 'mobileHeight' | 'is3DStoryWidget'>
      ) | { __typename?: 'Menu' } | (
        { __typename?: 'SsoWidget' }
        & Pick<Types.SsoWidget, 'providerId' | 'width' | 'height' | 'hideIfIneligible' | 'platform' | 'useCarousel'>
      ) | { __typename?: 'ContentItem' } | { __typename?: 'LinkMenuItem' }>>, rightside: Array<Types.Maybe<(
        { __typename?: 'Widget' }
        & Pick<Types.Widget, 'html' | 'platform'>
      ) | (
        { __typename?: 'ImageWidget' }
        & Pick<Types.ImageWidget, 'path' | 'platform'>
      ) | (
        { __typename?: 'ThemeAwareImageWidget' }
        & Pick<Types.ThemeAwareImageWidget, 'lightThemePath' | 'darkThemePath' | 'platform'>
      ) | { __typename?: 'PlacementWidget' } | { __typename?: 'Page' } | { __typename?: 'AdServiceWidget' } | (
        { __typename?: 'CustomAppWidget' }
        & Pick<Types.CustomAppWidget, 'url' | 'width' | 'height' | 'frameAttrs' | 'platform' | 'useCarousel'>
      ) | (
        { __typename?: 'DeepTargetWidget' }
        & Pick<Types.DeepTargetWidget, 'url' | 'width' | 'height' | 'mobileWidth' | 'mobileHeight' | 'is3DStoryWidget'>
      ) | { __typename?: 'Menu' } | (
        { __typename?: 'SsoWidget' }
        & Pick<Types.SsoWidget, 'providerId' | 'width' | 'height' | 'hideIfIneligible' | 'platform' | 'useCarousel'>
      ) | { __typename?: 'ContentItem' } | { __typename?: 'LinkMenuItem' }>>, footer: Array<Types.Maybe<(
        { __typename?: 'Widget' }
        & Pick<Types.Widget, 'html' | 'platform'>
      ) | (
        { __typename?: 'ImageWidget' }
        & Pick<Types.ImageWidget, 'path' | 'platform'>
      ) | (
        { __typename?: 'ThemeAwareImageWidget' }
        & Pick<Types.ThemeAwareImageWidget, 'lightThemePath' | 'darkThemePath' | 'platform'>
      ) | { __typename?: 'PlacementWidget' } | { __typename?: 'Page' } | { __typename?: 'AdServiceWidget' } | (
        { __typename?: 'CustomAppWidget' }
        & Pick<Types.CustomAppWidget, 'url' | 'width' | 'height' | 'frameAttrs' | 'platform' | 'useCarousel'>
      ) | (
        { __typename?: 'DeepTargetWidget' }
        & Pick<Types.DeepTargetWidget, 'url' | 'width' | 'height' | 'mobileWidth' | 'mobileHeight' | 'is3DStoryWidget'>
      ) | { __typename?: 'Menu' } | (
        { __typename?: 'SsoWidget' }
        & Pick<Types.SsoWidget, 'providerId' | 'width' | 'height' | 'hideIfIneligible' | 'platform' | 'useCarousel'>
      ) | { __typename?: 'ContentItem' } | { __typename?: 'LinkMenuItem' }>> }
    )> }
  )>>> }
);


export const GetDynamicWidgetDocument = gql`
    query GetDynamicWidget($id: String!) {
  pages(ids: [$id]) {
    widgets {
      middle {
        ... on Widget {
          html
          platform
        }
        ... on PlacementWidget {
          placementType
        }
        ... on ImageWidget {
          path
          platform
        }
        ... on ThemeAwareImageWidget {
          lightThemePath
          darkThemePath
          platform
        }
        ... on DeepTargetWidget {
          url
          width
          height
          mobileWidth
          mobileHeight
          is3DStoryWidget
        }
        ... on SsoWidget {
          providerId
          width
          height
          hideIfIneligible
          platform
          useCarousel
        }
        ... on CustomAppWidget {
          url
          width
          height
          frameAttrs
          platform
          useCarousel
        }
      }
      header {
        ... on Widget {
          html
          platform
        }
        ... on ImageWidget {
          path
          platform
        }
        ... on ThemeAwareImageWidget {
          lightThemePath
          darkThemePath
          platform
        }
        ... on DeepTargetWidget {
          url
          width
          height
          mobileWidth
          mobileHeight
          is3DStoryWidget
        }
        ... on SsoWidget {
          providerId
          width
          height
          hideIfIneligible
          platform
          useCarousel
        }
        ... on CustomAppWidget {
          url
          width
          height
          frameAttrs
          platform
          useCarousel
        }
      }
      rightside {
        ... on Widget {
          html
          platform
        }
        ... on ImageWidget {
          path
          platform
        }
        ... on ThemeAwareImageWidget {
          lightThemePath
          darkThemePath
          platform
        }
        ... on DeepTargetWidget {
          url
          width
          height
          mobileWidth
          mobileHeight
          is3DStoryWidget
        }
        ... on SsoWidget {
          providerId
          width
          height
          hideIfIneligible
          platform
          useCarousel
        }
        ... on CustomAppWidget {
          url
          width
          height
          frameAttrs
          platform
          useCarousel
        }
      }
      footer {
        ... on Widget {
          html
          platform
        }
        ... on ImageWidget {
          path
          platform
        }
        ... on ThemeAwareImageWidget {
          lightThemePath
          darkThemePath
          platform
        }
        ... on DeepTargetWidget {
          url
          width
          height
          mobileWidth
          mobileHeight
          is3DStoryWidget
        }
        ... on SsoWidget {
          providerId
          width
          height
          hideIfIneligible
          platform
          useCarousel
        }
        ... on CustomAppWidget {
          url
          width
          height
          frameAttrs
          platform
          useCarousel
        }
      }
    }
  }
}
    `;

/**
 * __useGetDynamicWidgetQuery__
 *
 * To run a query within a React component, call `useGetDynamicWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicWidgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDynamicWidgetQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicWidgetQuery, GetDynamicWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicWidgetQuery, GetDynamicWidgetQueryVariables>(GetDynamicWidgetDocument, options);
      }
export function useGetDynamicWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicWidgetQuery, GetDynamicWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicWidgetQuery, GetDynamicWidgetQueryVariables>(GetDynamicWidgetDocument, options);
        }
export type GetDynamicWidgetQueryHookResult = ReturnType<typeof useGetDynamicWidgetQuery>;
export type GetDynamicWidgetLazyQueryHookResult = ReturnType<typeof useGetDynamicWidgetLazyQuery>;
export type GetDynamicWidgetQueryResult = Apollo.QueryResult<GetDynamicWidgetQuery, GetDynamicWidgetQueryVariables>;