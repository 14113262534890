import { useEffect } from 'react';

import {
  setWizardStepLoading,
  setWizardStepAlertMessage,
  setWizardStepFieldsErrors,
  setWizardStepGenericErrors,
  setWizardStepUnhandledErrors,
} from './stepState';
import { WizardStepStateDispatcher } from './types';

interface WizardStepSucceedLoadedDispatcher {
  (dispatch: WizardStepStateDispatcher): void;
}

/**
 * Sets the correct state after step loading
 */
export const setWizardStepSucceedLoaded: WizardStepSucceedLoadedDispatcher = (dispatch) => {
  dispatch(setWizardStepLoading(false));
  dispatch(setWizardStepGenericErrors([]));
  dispatch(setWizardStepUnhandledErrors([]));
  dispatch(setWizardStepFieldsErrors([]));
  dispatch(setWizardStepAlertMessage(null));
};

/**
 * Hook: Sets the correct state after step loading
 */
export const useWizardStepSucceedLoaded: WizardStepSucceedLoadedDispatcher = (dispatch) => {
  useEffect(() => {
    setWizardStepSucceedLoaded(dispatch);
  }, [dispatch]);
};
