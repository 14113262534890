import React, { useContext } from 'react';

import { Banner } from '@ast/magma/components/banner';

import { CenterAlignContainer } from '@app/common/components/CenterAlignContainer/CenterAlignContainer';

import { ScsoContext, ScsoContextPropsDefault } from '@app/core/authentication/ScsoContext';
import { WidgetZone } from '@app/core/components/WidgetZone/WidgetZone';
import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { EligibilityMenuItemsContext } from '@app/core/contexts/eligibilityMenuContext/EligibilityMenuItemsContext';
import { useTranslation } from '@app/core/i18n';

import { ZoneName } from '../WidgetZone/constants';
import { UserBar } from './userbar/userbar';

import styles from './Header.pcss';
import { MainMenu } from './mainmenu';

export const Header: React.FC = () => {
  const { t } = useTranslation();
  const scsoContext = useContext(ScsoContext) ?? ScsoContextPropsDefault;
  const userContext = useCurrentUserInfoContext();

  const bannerText = t(
    'scso-banner-text|Text on the SCSO session information banner',
    'You are successfully logged in on behalf of user <strong>{{userName}}</strong> via support center admin <strong>{{adminName}}</strong>.',
    { userName: userContext?.userLogin ?? 'unknown', adminName: scsoContext?.adminLogin ?? 'unknown' },
  );

  return (
    <div>
      { scsoContext.isScso && (
        <Banner className={styles.scsoBanner} level="warning">{bannerText}</Banner>
      )}
      <header className={styles.header}>
        <CenterAlignContainer className={styles.container}>
          <div className={styles.navBar}>
            <WidgetZone
              zoneName={ZoneName.Header}
              className={styles.logoZone}
            />
            <UserBar />
          </div>
        </CenterAlignContainer>
        <div className={styles.menuHeader}>
          <CenterAlignContainer className={styles.menuContainer}>
            <EligibilityMenuItemsContext>
              <MainMenu />
            </EligibilityMenuItemsContext>
          </CenterAlignContainer>
        </div>
      </header>
    </div>
  );
};
