import React from 'react';

import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';

import styles from './Header.pcss';

export const NonAuthHeader: React.FC = () => (
  <header className={styles.nonAuthHeader}>
    <LocalizationSelectorWrapper />
  </header>
);
