import React, {
  forwardRef, HTMLAttributes, Ref, useEffect, useState,
} from 'react';

import classNames from 'classnames';

import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import { ApplicationLayout } from '@app/core/widgets/pages';

import styles from './Main.pcss';

export interface MainProps {
  layoutId: ApplicationLayout;
}

export const Main = forwardRef((props:MainProps & HTMLAttributes<HTMLDivElement>, ref:Ref<HTMLElement>) => {
  const {
    layoutId,
    className,
    children,
    ...otherMainProps
  } = props;
  const mainRef = useForwardedRef(ref);
  const [mainShade, setMainShade] = useState(false);

  // Raise with background image flag
  useEffect(() => {
    if (mainRef.current) {
      setMainShade(getComputedStyle(mainRef.current, ':before').backgroundImage !== 'none');
    }
  }, []);

  const css = classNames(
    className,
    // for embedded tethered mode we don't need background
    styles.mainZone,
    mainShade && styles.shade,
    layoutId === 'dashboard' && styles.dashboard,
  );

  return (
    <main
      id="MainPage"
      data-stable-name="MainPage"
      ref={mainRef}
      className={css}
      {...otherMainProps}
    >
      {children}
    </main>
  );
});
