import { history } from '@app/core/browserHistory';

import { safeParseURL } from './safeParseURL';

/**
 * Navigates to the provided URL if the URL is valid:
 * - if the URL is relative, a history item is pushed to avoid a full page reload.
 * - if the URL is absolute, the `location` is updated.
 *
 * Using the history API for relative URLs also allows to avoid logouts during redirects in Safari.
 *
 * @throws an error if the provided URL is invalid and can not be parsed as an absolute or relative URL.
 */
export function navigate(url: string) {
  const result = safeParseURL(url, window.location.origin);

  // If the URL can not be parsed as an absolute or relative URL the navigation can not be performed.
  if (result.type === 'error') {
    throw result.error;
  }

  const { value } = result;
  switch (value.type) {
    case 'relative':
      history.push(url);
      break;

    case 'absolute':
    default:
      window.location.assign(url);
      break;
  }
}
