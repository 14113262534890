import React, {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  Ref,
  useEffect,
  useMemo,
} from 'react';

import classNames from 'classnames';

import { Loader } from '@ast/magma/components/loader';

import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import { WidgetCarousel } from './components/WidgetCarousel/WidgetCarousel';
import { useZoneWidgets } from './hooks/useZoneWidgets';
import { hasWidgetCarousel } from './utils/hasWidgetCarousel';
import { pickVisibleElements } from './utils/pickVisibleElements';
import { splitCarouselWidgets } from './utils/splitCarouselWidgets';

import { ZoneName } from './constants';
import styles from './WidgetZone.pcss';

/**
 * Widget zone component properties.
 */
export interface WidgetZoneProps {
  /**
   * Widget zone name.
   */
  readonly zoneName: ZoneName;

  /**
   * Callback on widgets are rendered.
   */
  readonly onRenderWidgets?: (widgetCount: number) => void;
}

/**
 * Widget zone component.
 *
 * Renders widgets for a specific zone in a vertical list and/or in a horizontal widget carousel if widgets request it.
 */
export const WidgetZone = React.memo(forwardRef(
  (
    props: WidgetZoneProps & HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { zoneName, onRenderWidgets, ...otherDivProps } = props;

    const zoneContainerRef: MutableRefObject<HTMLDivElement | null> = useForwardedRef<HTMLDivElement>(ref);

    const { loading, readyWidgets } = useZoneWidgets(zoneName);

    useEffect(() => {
      if (loading) {
        return;
      }

      if (onRenderWidgets) {
        onRenderWidgets(readyWidgets.length);
      }
    }, [readyWidgets, loading]);

    // Split the carousel and list widgets into groups.
    const { carouselWidgets, listWidgets } = useMemo(() => {
      const { matching, other } = splitCarouselWidgets(readyWidgets);

      return {
        carouselWidgets: matching,
        listWidgets: other,
      };
    }, [readyWidgets]);

    // Extract the visible carousel elements.
    const carouselElements = useMemo(() => pickVisibleElements(carouselWidgets), [carouselWidgets]);

    const hasCarousel = hasWidgetCarousel(zoneName);
    const useCarousel = hasCarousel && carouselElements.length > 0;

    return (
      <React.Suspense fallback={<Loader size="md" />}>
        <div
          ref={zoneContainerRef}
          widget-zone={zoneName}
          {...otherDivProps}
          data-stable-name="WidgetZone"
        >
          {listWidgets.map(({ id, element }) => (
            element ? (
              <div className={classNames({ [styles.listElement]: hasCarousel })} key={id}>
                {element}
              </div>
            ) : null
          ))}
          {useCarousel && <WidgetCarousel widgets={carouselWidgets} />}
        </div>
      </React.Suspense>
    );
  },
));
