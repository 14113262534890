import { useRef, useLayoutEffect } from 'react';

export const useDocumentTitle = (title: string, retainOnUnmount = false, rewritePreviousTitle = false) => {
  const defaultTitle = useRef(document.title);

  useLayoutEffect(() => {
    if (rewritePreviousTitle) {
      defaultTitle.current = document.title;
    }
    document.title = title;
  }, [title, rewritePreviousTitle]);

  useLayoutEffect(() => () => {
    if (!retainOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [retainOnUnmount]);
};
